import React from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, makeStyles } from "@material-ui/core";
import { useAppState } from "../../states/app-state";
import { Observer, observer } from "mobx-react-lite";
import DisabledClickLoading from "../../components/DisabledClickLoading";

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            padding: theme.spacing(1),
        },
    },
    labelCheckBox: {
        display: 'block',
    },
    btnConfirm: {
        width: '100%',
        color: theme.palette.common.white,
    },
}),
);


const VersionAgreement: React.FC = observer(() => {
    const classes = useStyles();
    const appState = useAppState();
    const open = appState.profile.agreementNeeded;

    const [loading, setLoading] = React.useState(false);
    const handleAgree = () => {
        try {
            setLoading(true);
            appState.profile.handleUpdateAgreedVersion();
        } catch (err) {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        appState.getWebAppRemoteConfigParameters();
    }, [appState, open]);

    const [checked, setChecked] = React.useState(true);
    const handleChange = (event: any) => {
        setChecked(event.target.checked);
    };
    return (

        <Dialog PaperProps={{ style: { borderRadius: "10px" } }}
            className={classes.dialogContainer}
            open={open}
            maxWidth="sm"
        >
            <DialogContent>
                <DialogContentText>
                    We updated our Terms & Conditions and Privacy Policy :
                </DialogContentText>
                <FormControlLabel
                    className={classes.labelCheckBox}
                    control={<Checkbox color="primary" checked={checked} onChange={handleChange} />}
                    label={
                        <>
                            I agree to the <a href={appState.webAppRemoteConfigParameters?.legal} target="_blank" rel="noreferrer">Terms & Conditions </a>and <a href={appState.webAppRemoteConfigParameters?.privacyPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>.
                        </>
                    }
                />
            </DialogContent>
            <DialogActions >
                <Observer>{() => {
                    const valid = !checked;
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAgree}
                            disabled={valid}
                            className={classes.btnConfirm}
                        >
                            Confirm
                        </Button>
                    )
                }}</Observer>
                {loading && <DisabledClickLoading />}
            </DialogActions>
        </Dialog>

    );
})
export default VersionAgreement;
