import React from 'react';
import PriceEditor from './PriceEditor';
import { IOrderState } from '../../states/app-state';
import styles from './PriceHandler.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const PriceHandler: React.FC<{ selectedOrder: IOrderState }> = ({ selectedOrder }) => {


    return (
        <div className={styles.root}>
            <PriceEditor />
        </div>
    );
};

export default PriceHandler;