import { decodePolyline, T3DFailedServerTransportState, TArrivedTOriginServerTransportState, TAuthorisedServerTransportState, TFirstTryServerTransportState, TNoActiveTransportServerTransportState, TNotRatedServerTransportState, TParcelDroppedOffAtDestinationServerTransportState, TParcelPickedUpServerTransportState, TProcessingPaymentServerTransportState, TRiderArrivedToDestinationServerTransportState, TRiderOnTheWayToOriginServerTransportState, TSecondTryServerTransportState, TServerTransportState, TShowAlertServerTransportState, TTransportOption } from "../api/bearer-api";
import { error } from "../app/log"; // added by Bearer Web Developer
import { ApprovalMethodEnum, DestinationState, DiscountState, IDestinationState, IDiscountState, IOrderState, IOriginState, ISimpleMessageState, ITransportState, OrderState, OriginState, ParcelType, PaymentStepEnum, SearchRiderEnum, SimpleMessageState, TransportState, TransportStateEnum, TransportTypeEnum } from "./app-state";

export function extractOrders(transports: undefined | { [id: string]: TServerTransportState }, parcelTypes: ParcelType[]): IOrderState[] {
    if (!transports) {
        return [];
    }
    const orders = Object.keys(transports).map(id => extractOrder(transports[id], parcelTypes)).filter(i => !!i) as IOrderState[];
    return orders;
}

export function extractMessages(transports: undefined | { [id: string]: TServerTransportState }, parcelTypes: ParcelType[]): ISimpleMessageState[] {
    if (!transports) {
        return []
    }
    const messages = Object.keys(transports).map(id => extractMessage(transports[id], parcelTypes)).filter(i => !!i) as ISimpleMessageState[];
    return messages;
}

function extractMessage(transport: TServerTransportState, parcelTypes: ParcelType[]): ISimpleMessageState | undefined {
    if (transport.state === 'Show Alert') {
        return extractAlert(transport, parcelTypes);
    }
}

function extractOrder(transport: TServerTransportState, parcelTypes: ParcelType[]): IOrderState | void { // // undefined modified to void by Bearer Web Developer
    if (transport.state === 'No Active Transport') {
        return extractNoActiveTransport(transport, parcelTypes)
    }
    else if (transport.state === 'Show Alert') {
        return undefined;
    }
    else if (transport.state === 'Processing Payment') {
        return extractProcessingPayment(transport, parcelTypes)
    }
    else if (transport.state === 'Authorised') {
        return extractAuthorised(transport, parcelTypes);
    }
    if (transport.state === '3DS Failed') {
        return extract3dsFailed(transport, parcelTypes);
    }
    else if (transport.state === 'First Try') {
        return extractFirstTry(transport, parcelTypes);
    }
    else if (transport.state === 'Second Try') {
        return extractSecondTry(transport, parcelTypes);
    }
    else if (transport.state === 'Rider on the way to Origin') {
        return extractRiderOnTheWayToOrigin(transport, parcelTypes)
    }
    else if (transport.state === 'Arrived to Origin') {
        return extractArrivedToOrigin(transport, parcelTypes)
    }
    else if (transport.state === 'Parcel Picked Up') {
        return extractParcelPickedUp(transport, parcelTypes)
    }
    else if (transport.state === 'Rider Arrived to Destination') {
        return extractRiderArrivedToDestination(transport, parcelTypes)
    }
    else if (transport.state === 'Parcel Dropped Off at Destination') {
        return extractParcelDroppedOffAtDestination(transport, parcelTypes)
    }
    else if (transport.state === 'Not Rated Yet') {
        return extractNotRated(transport, parcelTypes)
    }
    return transport;
}

function extract3dsFailed(transport: T3DFailedServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetails(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: true,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
            step: PaymentStepEnum.SelectMethod,
        },
    })
    return order;
}

function extractAuthorised(transport: TAuthorisedServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetails(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        searchRider: {
            open: true,
            step: SearchRiderEnum.Searching,
        }
    })
    return order;
}

function extractProcessingPayment(transport: TProcessingPaymentServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetails(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
            dynamicPassword: {
                actionType: transport.actionType,
                actionUrl: transport.actionUrl,
            }

        },

    })
    return order;
}

function extractFirstTry(transport: TFirstTryServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetails(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: true,
            step: SearchRiderEnum.Searching,
        },
    })
    return order;
}

function extractSecondTry(transport: TSecondTryServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetails(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: true,
            step: SearchRiderEnum.Searching,
        },
    })
    return order;
}

function extractRiderOnTheWayToOrigin(transport: TRiderOnTheWayToOriginServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetailWithRiderInfo(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: false,
        },
        transportMessage: {
            messageText: transport.state_description,
            messageType: Object.values(TransportStateEnum).find(f => f === transport.state),
        },

    })
    return order;
}

function extractArrivedToOrigin(transport: TArrivedTOriginServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetailWithRiderInfo(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: false,
            step: SearchRiderEnum.RiderFound,
        },
        transportMessage: {
            messageText: transport.state_description,
            messageType: Object.values(TransportStateEnum).find(f => f === transport.state),
        },
    })
    return order;

}

function extractParcelPickedUp(transport: TParcelPickedUpServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetailWithRiderInfo(transport),

        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: false,
        },
        transportMessage: {
            messageText: transport.state_description,
            messageType: Object.values(TransportStateEnum).find(f => f === transport.state),
        },
    })
    return order;
}

function extractRiderArrivedToDestination(transport: TRiderArrivedToDestinationServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetailWithRiderInfo(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: false,
        },
        transportMessage: {
            messageText: transport.state_description,
            messageType: Object.values(TransportStateEnum).find(f => f === transport.state),
        },
    })
    return order;
}

function extractParcelDroppedOffAtDestination(transport: TParcelDroppedOffAtDestinationServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        origin: extractOrigin(transport),
        destination: extractDestination(transport),
        parcelType: {
            editing: false,
            type: parcelTypes.find(t => t.parcel_type === transport.parcel_type),
        },
        transport: extractTransportDetailWithRiderInfo(transport),
        priceListId: transport.price_list_id,
        transportOptions: {
            cycling: extractCycling(transport),
            riding: extractRiding(transport),
            walking: extractWalking(transport),
            selectedOption: Object.values(TransportTypeEnum).find(i => i === transport.bearer_type),
        },
        discount: extractDiscountCode(transport),
        payment: {
            open: false,
            fareUpdateData: {
                transport_original_price: transport.transport_original_price,
            },
        },
        searchRider: {
            open: false,
        },
        transportMessage: {
            messageText: transport.state_description,
            messageType: Object.values(TransportStateEnum).find(f => f === transport.state),
        },
    })
    return order;
}

function extractNotRated(transport: TNotRatedServerTransportState, parcelTypes: ParcelType[]): IOrderState {
    const order = OrderState.create({
        id: transport.transport_id,
        transport: extractRate(transport),
    })
    return order;
}

function extractNoActiveTransport(transport: TNoActiveTransportServerTransportState, parcelTypes: ParcelType[]): IOrderState | void { // void type added by Bearer Web Developer
    error("Function not implemented."); // throw new Error modified by Bearer Web Developer

}

function extractOrigin(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): IOriginState {
    const origin = OriginState.create({
        address: {
            location: {
                lat: transport.origin_lat,
                lng: transport.origin_lng,
            },
            description: transport.origin_address,
        },
        editing: false,
        name: transport.sender_name,
        phone: transport.sender_phone_number,
        moreDetails: transport.origin_details,
    })

    return origin;
}


function extractDestination(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): IDestinationState {
    const destination = DestinationState.create({
        address: {
            location: {
                lat: transport.destination_lat,
                lng: transport.destination_lng,
            },
            description: transport.destination_address,
        },
        name: transport.recipient_name,
        phone: transport.recipient_phone_number,
        moreDetails: transport.destination_details,
        editing: false,
        approvalMethods: Object.values(ApprovalMethodEnum).find(k => k === transport.dropoff_verification_method),
    })

    return destination;
}

function extractCycling(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): TTransportOption | null | undefined {
    return transport.bearer_type !== 'cycling' ? null : {
        destination_address: transport.destination_address,
        duration: transport.transport_duration,
        origin_address: transport.origin_address,
        overview_polyline_latlng: decodePolyline(transport.overview_polyline),
        price: transport.transport_original_price,
        time: transport.transport_time,
        length: transport.transport_length,
        type: 'cycling',
    };
}

function extractRiding(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): TTransportOption | null | undefined {
    return transport.bearer_type !== 'riding' ? null : {
        destination_address: transport.destination_address,
        duration: transport.transport_duration,
        origin_address: transport.origin_address,
        overview_polyline_latlng: decodePolyline(transport.overview_polyline),
        price: transport.transport_original_price,
        time: transport.transport_time,
        length: transport.transport_length,
        type: 'riding',
    };
}

function extractWalking(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): TTransportOption | null | undefined {
    return transport.bearer_type !== 'walking' ? null : {
        destination_address: transport.destination_address,
        duration: transport.transport_duration,
        origin_address: transport.origin_address,
        overview_polyline_latlng: decodePolyline(transport.overview_polyline),
        price: transport.transport_original_price,
        time: transport.transport_time,
        length: transport.transport_length,
        type: 'walking',
    };
}

function extractDiscountCode(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): IDiscountState {
    let value = undefined;
    if (transport.transport_receivable_price !== transport.transport_original_price) {
        value = { transport_receivable_price: transport.transport_receivable_price, }
    } const discountCode = DiscountState.create({
        value
    });
    return discountCode;
}

function extractTransportDetails(transport: TAuthorisedServerTransportState | TFirstTryServerTransportState | T3DFailedServerTransportState | TProcessingPaymentServerTransportState
    | TSecondTryServerTransportState | TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): ITransportState {
    const step = Object.values(TransportStateEnum).find(i => i === transport.state);
    const transportDetails = TransportState.create({
        editing: false,
        value: {
            bearer_type: transport.bearer_type,
            destination_address: transport.destination_address,
            destination_details: transport.destination_details,
            dropoff_verification_method: transport.dropoff_verification_method,
            origin_address: transport.origin_address,
            origin_details: transport.origin_details,
            overview_polyline: transport.overview_polyline,
            price_list_id: transport.price_list_id,
            order_secret: transport.order_secret,
            order_number: transport.order_number,
            parcel_type: transport.parcel_type,
            transport_type: transport.bearer_type,
            transport_id: transport.transport_id,
            transport_time: transport.transport_time,
            transport_original_price: transport.transport_original_price,
            transport_receivable_price: transport.transport_receivable_price,
            sender_name: transport.sender_name,
            sender_phone_number: transport.sender_phone_number,
            recipient_name: transport.recipient_name,
            recipient_phone_number: transport.recipient_phone_number,
            parcel_description: transport.parcel_description,
            parcel_max_weight: transport.parcel_max_weight,
            parcel_min_weight: transport.parcel_min_weight,
            transport_length: transport.transport_length,
            transport_duration: transport.transport_duration,
        },
        step,
        shouldInformServerOnCancel: step === TransportStateEnum.ThreeDSFailed,
    });
    return transportDetails;
}

function extractTransportDetailWithRiderInfo(transport: TRiderOnTheWayToOriginServerTransportState | TArrivedTOriginServerTransportState | TParcelPickedUpServerTransportState
    | TRiderArrivedToDestinationServerTransportState | TParcelDroppedOffAtDestinationServerTransportState): ITransportState {
    const step = Object.values(TransportStateEnum).find(i => i === transport.state);
    const transportDetail = TransportState.create({
        editing: false,
        step,
        value: {
            bearer_type: transport.bearer_type,
            destination_address: transport.destination_address,
            destination_details: transport.destination_details,
            dropoff_verification_method: transport.dropoff_verification_method,
            origin_address: transport.origin_address,
            origin_details: transport.origin_details,
            overview_polyline: transport.overview_polyline,
            price_list_id: transport.price_list_id,
            order_secret: transport.order_secret,
            order_number: transport.order_number,
            parcel_type: transport.parcel_type,
            transport_type: transport.bearer_type,
            transport_id: transport.transport_id,
            transport_time: transport.transport_time,
            transport_original_price: transport.transport_original_price,
            transport_receivable_price: transport.transport_receivable_price,
            sender_name: transport.sender_name,
            sender_phone_number: transport.sender_phone_number,
            recipient_name: transport.recipient_name,
            recipient_phone_number: transport.recipient_phone_number,
            parcel_description: transport.parcel_description,
            parcel_max_weight: transport.parcel_max_weight,
            parcel_min_weight: transport.parcel_min_weight,
            transport_length: transport.transport_length,
            transport_duration: transport.transport_duration,
        },
        riderInfo: {
            bearer_type: transport.bearer_type,
            origin_lat: transport.origin_lat,
            origin_lng: transport.origin_lng,
            origin_address: transport.origin_address,
            destination_lat: transport.destination_lat,
            destination_lng: transport.destination_lat,
            destination_address: transport.destination_address,
            order_secret: transport.order_secret,
            cancellation_fee: transport.cancellation_fee,
            rider_avatar_url: transport.rider_avatar_url,
            rider_given_name: transport.rider_given_name,
            rider_last_name: transport.rider_last_name,
            motorbike_make: transport.motorbike_make,
            motorbike_model: transport.motorbike_model,
            motorbike_registration_number: transport.motorbike_registration_number,
            motorbike_registration_state: transport.motorbike_registration_state,
            transport_id: transport.transport_id,
            state_description: transport.state_description,
        },
    });
    return transportDetail;
}

function extractRate(transport: TNotRatedServerTransportState): ITransportState {
    const rate = TransportState.create({
        riderInfo: {
            bearer_type: transport.bearer_type,
            origin_address: transport.origin_address,
            destination_address: transport.destination_address,
            cancellation_fee: transport.cancellation_fee,
            rider_avatar_url: transport.rider_avatar_url,
            rider_given_name: transport.rider_given_name,
            rider_last_name: transport.rider_last_name,
            motorbike_make: transport.motorbike_make,
            motorbike_model: transport.motorbike_model,
            motorbike_registration_number: transport.motorbike_registration_number,
            motorbike_registration_state: transport.motorbike_registration_state,
            transport_id: transport.transport_id,
        },
        step: Object.values(TransportStateEnum).find(i => i === transport.state),
        value: {
            transport_id: transport.transport_id,
        }

    });
    return rate;
}



function extractAlert(transport: TShowAlertServerTransportState, parcelTypes: ParcelType[]): ISimpleMessageState {
    const message = SimpleMessageState.create({
        message: transport.alert_text,
    })
    return message;
}

