
import React from "react";
import { Button, Dialog } from "@material-ui/core"
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading"; // added by Bearer Web Developer
import styles from './CheckDevicesPopup.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const CheckDevicesPopup: React.FC = () => {
    const appState = useAppState();
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const open = appState.checkingDevice;
    const handleRetry = async () => {
        try {
            setLoading(true);
            setError('');
            // modified by Bearer Web Developer start
            const r2: boolean = await appState.updateCustomerFcmToken();
            if (r2) {
                const r3: boolean = await appState.getCustomerAppState();
                if (r3) {
                    await appState.profile.handleVersionControl();
                    appState.setCheckingDevice(false);
                }
            }
            setLoading(false);
            // modified by Bearer Web Developer end
        } catch (err: any) {
            setLoading(false);
            setError(err)
        }
    }
    // modified by Bearer Web Developer start
    return (
        <>
            {loading ? <DisabledClickLoading /> :
                appState.checkingDevice && <Dialog open={open} PaperProps={{ style: { borderRadius: "10px" } }}>
                    <div className={styles.check_devices_popup}>
                        <p>
                            You cannot use this application on more than ONE device at any given time. However in a few minutes you will be allowed to use your second device to log in
                        </p>
                        <Button
                            className={styles.button}
                            onClick={handleRetry}
                            color="primary"
                            variant="contained"
                        >
                            Retry
                        </Button>
                    </div>
                </Dialog>}
        </>
    )
    // modified by Bearer Web Developer end
}
export default CheckDevicesPopup;