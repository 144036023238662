import React from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementOptions, loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './StripePayment.module.scss'; // added by Bearer Web Developer

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    hidePostalCode: true,
    hideIcon: true,
    style: {
        base: {
            padding: "13px 8px 14px 8px",
            color: '#32325d',
            fontFamily: '"GothamRegular", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#858585'
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        },
        
    },

    classes:{

    },
    
};
// useStyles removed by Bearer Web Developer

const CheckoutForm = () => {
    const [error, setError] = useState<string | null>(null);
    const stripe = useStripe();
    const elements = useElements();
    const appState = useAppState();



    // Handle real-time validation errors from the card Element.
    const handleChange = (event: any) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    const [loading, setLoading] = React.useState(false);
    // Handle form submission.
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const card = elements?.getElement(CardElement);
        setLoading(true);
      
        if (card && stripe) {
            setLoading(true);
            const result = await stripe.createToken(card)
            if (result.error) {
                // Inform the user if there was an error.
                setError(result.error.message || null);
                setLoading(false)
            } else if (result.token) {
                setError(null);
                // Send the token to your server.
                // stripeTokenHandler(result.token);
                await appState.selectedOrder?.payment.addCard(result.token.id,result?.token?.card?.id as string); // modify by Bearer Web Developer (result?.token?.card?.id added)
                card.clear();
                setLoading(false)
            }

        }
    };
    {/* modified by Bearer Web Developer start */}
    return (
        <form onSubmit={handleSubmit} className={styles.stripe_payment}>
            <div className="">
                <div className={styles.card}>
                    <div>
                        <img src="/credit_card.png" alt="" />
                    </div>
                    <div className={styles.card_element_box}>
                        <CardElement
                            id="card-element"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={handleChange}
                            className={styles.card_element}

                        />
                        <div className="card-errors" role="alert">{error}</div>
                    </div>
                        <Button type="submit" disableRipple={true} className={styles.add} disabled={loading}>+ Add</Button>
                        {loading && <DisabledClickLoading />}
                </div>
                {/* the fallowing not commented by Bearer Web Developer */}
                {/* <CardElement
                    id="card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange}
                />
                <div className="card-errors" role="alert">{error}</div>
                <Button color="primary" type="submit">Add</Button> */}
            </div>
        </form>
    );
    {/* modified by Bearer Web Developer end */}
}



// Setup Stripe.js and the Elements provider

const StipePayment = () => {
    const appState = useAppState();

    React.useEffect(() => {
        appState.getWebAppRemoteConfigParameters();
    }, [appState]);

    const stripePromise = loadStripe(appState.webAppRemoteConfigParameters?.stripeKey || "");
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
}

export default StipePayment;

// POST the token ID to your backend.
// async function stripeTokenHandler(token: any) {
//     const response = await fetch('/charge', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({ token: token.id })
//     });

//     return response.json();
// }