import { GoogleMap, Marker, PolylineF } from "@react-google-maps/api"; // map version updated by Bearer Web Developer
import OriginIcon from "../../assets/images/origin-map.png";
import DestinationIcon from "../../assets/images/destination-map.png";
import { decodePolyline, Geopoint, TTransportsHistoryInfo } from "../../api/bearer-api";
import { Location, useAppState } from "../../states/app-state";
import React from "react";
import BearerMapWrapper from "../map/bearerMapWrapper";

// in this component map version updated by Bearer Web Developer
interface IGoogleMapProps {
    transportHistoryInfo: TTransportsHistoryInfo
}
function Map({ transportHistoryInfo }: IGoogleMapProps) {
    const appState = useAppState();
    const environmentCode = appState.environmentCode;
    const mapId = environmentCode === "STAGING" ? "f4e86786fbb6b3bb" :
        environmentCode === "PRODUCTION" ? "683a7c74fe921546" : "39e429275f5344f2";
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const onLoad = (mapInstance: google.maps.Map) => {
        // do something with map Instance
        // console.log(mapInstance);
        setMap(mapInstance)
    }

    const p_point = React.useMemo(() => decodePolyline(transportHistoryInfo.overview_polyline), [transportHistoryInfo.overview_polyline]);

    const originLocation = React.useMemo(() => geoToLocation(transportHistoryInfo.origin_geopoint), [transportHistoryInfo.origin_geopoint]);
    const destinationLocation = React.useMemo(() => geoToLocation(transportHistoryInfo.destination_geopoint), [transportHistoryInfo.destination_geopoint]);

    const bounds = new google.maps.LatLngBounds();
    p_point?.forEach(p => bounds.extend(p));
    bounds.extend(originLocation);
    bounds.extend(destinationLocation);

    const options: google.maps.MapOptions = {
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            bounds: bounds

        } as google.maps.ZoomControlOptions,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        panControl: false,
        rotateControl: false,
        fullscreenControl: false,
        // zoom: 17, // commented by Bearer Web Developer
        // minZoom: 14.4, // added by Bearer Web Developer (zoom not worked so I used minZoom)
        // Update while changing STAGE
        mapId: mapId,
        // ...otherOptions
        draggable: false,
    }
    if (map) map?.fitBounds(bounds);

    return (
        <GoogleMap
            mapContainerStyle={{
                width: "100%",
                height: "100%",
            }}
            options={options}
            onLoad={onLoad}
        >
            <Marker
                icon={{
                    url: OriginIcon,
                    anchor: new google.maps.Point(12, 36),
                    scaledSize: new google.maps.Size(24, 36),
                }}
                position={originLocation}
            />
            <Marker
                icon={{
                    url: DestinationIcon,
                    anchor: new google.maps.Point(12, 36),
                    scaledSize: new google.maps.Size(24, 36),
                }}
                position={destinationLocation}

            />
            <PolylineF
                path={p_point}
                options={{
                    strokeColor: 'blue',
                    strokeOpacity: 1,
                    strokeWeight: 4,
                }}
            />
        </GoogleMap>
    )

}

function TransportRouteMap({ transportHistoryInfo }: IGoogleMapProps) {
    return (
        <BearerMapWrapper>
            <Map transportHistoryInfo={transportHistoryInfo} />
        </BearerMapWrapper>
    )
}

export default TransportRouteMap;

function geoToLocation(point: Geopoint): Location {
    return { lat: point._latitude, lng: point._longitude }
}