// this file added by Bearer Web Developer

import { Instance, types } from "mobx-state-tree";

// UserState added by Bearer Web Developer start
export interface Status {
    status: "active" | "deleted" | "non-active"
}

export const UserState = types
    .model("UserState", {
        status: types.maybeNull(types.frozen<Status>()),
    })
    .views(self => ({
        get userState() {
            return self.status;
        },

    }))
    .actions(self => {
        return {
            setUserState(value: Status | null) {
                self.status = value;
            },
        }
    })
export interface IUserState extends Instance<typeof UserState> { };
// UserState added by Bearer Web Developer end