/* this file added by Bearer Web Developer  */
import { useAppState } from '../../../../../states/app-state';
import styles from './information.module.scss';

const Information: React.FC = () => {
    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders.activeManageOrder;
    return (
        <div className={styles.information}>
            <ul>
                <li>
                    <img src="/person-black.webp" alt="" width={21} height={21} />
                    Recipient’s Name:<br />{activeManageOrder?.recipient_name}
                </li>
                <li>
                    <img src="/orders-grey.webp" alt="" width={21} height={21} />
                    Drop off Method:<br />{activeManageOrder?.dropoff_verification_method}
                </li>
                <li>
                    <img src="/call-grey.webp" alt="" width={21} height={21} />
                    Recipient’s Number:<br />{activeManageOrder?.recipient_phone_number}
                </li>
                <li>
                    <img src="/weight-grey.webp" alt="" width={21} height={21} />
                    Parcel Weight:<br />{`${activeManageOrder?.parcel_min_weight} ~ ${activeManageOrder?.parcel_max_weight} KG`}
                </li>
            </ul>
        </div>
    )
}
export default Information;