import React, { useState } from "react";
import { Location, useAppState } from "../../states/app-state";
import { GoogleMap, Marker } from "@react-google-maps/api"; // map version updated by Bearer Web Developer
import OriginIcon from "../../assets/images/origin-map.png";
import BearerMapWrapper from "../map/bearerMapWrapper";

// in this component map version updated by Bearer Web Developer
interface IGoogleMapProps {
    location?: Location;
}

const Map: React.FC<IGoogleMapProps> = ({ location }) => {

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const appState = useAppState();
    const environmentCode = appState.environmentCode;
    const mapId = environmentCode === "STAGING" ? "f4e86786fbb6b3bb" :
        environmentCode === "PRODUCTION" ? "683a7c74fe921546" : "39e429275f5344f2";


    
    const onLoad = (mapInstance: google.maps.Map) => {
        // do something with map Instance
        // console.log(mapInstance);
        setMap(mapInstance);
    }

    const options: google.maps.MapOptions = {
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
        } as google.maps.ZoomControlOptions,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        panControl: false,
        rotateControl: false,
        fullscreenControl: false,
        // Update while changing STAGE
        mapId: mapId,
        // ...otherOptions
        zoom:17
    }
    const bounds = new google.maps.LatLngBounds();
    if (location) bounds.extend(location);
    else bounds.extend({ lat: -37.813628, lng: 144.963058, })
    const center = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() };
    if (map) {
        map?.fitBounds(bounds);
    }
    return (
        <GoogleMap
            id='favourite-place-map'
            mapContainerStyle={{
                width: "100%",
                height: "100%",
            }}
            center={center}
            options={options}
            onLoad={onLoad}
        >
            {location &&
                <Marker
                    icon={{
                        url: OriginIcon,
                        anchor: new google.maps.Point(12, 36),
                        scaledSize: new google.maps.Size(40, 68),
                    }}
                    position={location}
                />
            }
        </GoogleMap>
    );
};

const FavouritePlaceMap: React.FC<IGoogleMapProps> = ({ location }) => {
    return (<BearerMapWrapper><Map location={location} /></BearerMapWrapper>)
}

export default FavouritePlaceMap




