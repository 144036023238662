import React, { useContext, useEffect } from "react";
import { useTheme } from "@material-ui/core";
import { useAppState, Location } from "../../states/app-state";
import { GoogleMap, Marker, PolylineF } from "@react-google-maps/api"; // map version updated by Bearer Web Developer
import { observer } from "mobx-react-lite";
import OriginIcon from "../../assets/images/origin-map.png";
import DestinationIcon from "../../assets/images/destination-map.png";
import riderIcon from "../../assets/images/rider-icon.svg"; // added by Bearer Web Developer
import { log } from "../../app/log"; // added by Bearer Web Developer
import BearerMapWrapper from "./bearerMapWrapper"; // added by Bearer Web Developer
import styles from "./BearerMap.module.scss"; // added by Bearer Web Developer
import { MenuDrawerContext } from "../../states/contexts/menuDrawer"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


// in this component map version updated by Bearer Web Developer
const Map: React.FC = observer(() => {
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const onLoad = (mapInstance: google.maps.Map) => {
        // do something with map Instance
        // console.log(mapInstance);
        setMap(mapInstance);
    }

    const appState = useAppState();
    const selectedOrder = appState.selectedOrder;
    const environmentCode = appState.environmentCode;
    const mapId = environmentCode === "STAGING" ? "f4e86786fbb6b3bb" :
        environmentCode === "PRODUCTION" ? "683a7c74fe921546" : "39e429275f5344f2";
    const theme = useTheme();
    const mapRef = React.useRef<any>(null);
    const p_points = selectedOrder?.selectedTransportOption?.overview_polyline_latlng;

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            log("Geolocation is not supported by this browser."); // modify by Bearer Web Developer
        }
    }

    const showPosition = (position: any) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const bounds = new google.maps.LatLngBounds();
        bounds.extend({ lat, lng });
    }

    function createPath(o?: Location, d?: Location): Location[] {
        return [o, d].filter(l => !!l) as Location[]
    }
    const d = selectedOrder?.destination.address?.location;
    const o = selectedOrder?.origin.address?.location;
    const r = selectedOrder?.rider.location; // added by Bearer Web Developer for extend of rider location
    const bounds = new google.maps.LatLngBounds();
    o && bounds.extend(o)
    d && bounds.extend(d)
    r && bounds.extend(r) // added by Bearer Web Developer for extend of rider location
    const pathPreview = createPath(o, d);
    p_points?.forEach(p => bounds.extend(p));
    if (!r && !o && !d && !p_points) { // !r  added by Bearer Web Developer for extend of rider location
        bounds.extend({
            lat: -38.608128,
            lng: 144.572079,
        });
        bounds.extend({
            lat: -37.572079,
            lng: 145.597504,
        })
    }

    const options: google.maps.MapOptions = {
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            bounds: bounds,
            initialCenter: {
                lat: -37.813628,
                lng: 144.963058,
            }
        } as google.maps.ZoomControlOptions,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        panControl: false,
        rotateControl: false,
        fullscreenControl: false,
        // Update while changing STAGE
        mapId: mapId,
        // ...otherOptions
    }
    if (map) {
        map?.fitBounds(bounds);
        map?.setOptions({
            draggable: (!selectedOrder?.origin.address || !selectedOrder?.destination.address)
        });

        if ((selectedOrder?.origin.address && !selectedOrder?.destination.address) ||
            (selectedOrder?.destination.address && !selectedOrder?.origin.address)) map?.setZoom(17)
    }
    // added by Bearer Web Developer start (for mobile: after open map in mobile state, map must be rerender and show bound correctly (bound is to small))
    const { swipe } = useContext(MenuDrawerContext); 
    useEffect(() => {
        if (swipe && map) map?.fitBounds(bounds);
    },[swipe])
    // added by Bearer Web Developer end

    return (
        <GoogleMap
            ref={mapRef}
            options={options}
            onLoad={onLoad}
            mapContainerStyle={{
                width: "100%",
                height: "100%",
            }}
            mapContainerClassName={styles.mapContainerClassName}
            id='main-bearer-map'
        >
            {
                selectedOrder?.origin.address?.location && <Marker
                    icon={{
                        url: OriginIcon,
                        anchor: new google.maps.Point(22, 66),
                        scaledSize: new google.maps.Size(40, 68),
                    }}
                    position={selectedOrder.origin.address.location}
                />
            }
            {
                selectedOrder?.destination.address?.location && <Marker
                    icon={{
                        url: DestinationIcon,
                        anchor: new google.maps.Point(22, 66),
                        scaledSize: new google.maps.Size(40, 68)
                    }}
                    position={selectedOrder.destination.address.location}
                />
            }
            {/* rider_location added by Bearer Web Developer start*/}
            {
                selectedOrder?.rider.location && <Marker
                    icon={{
                        url: riderIcon,
                        //anchor: new google.maps.Point(0,0),
                        scaledSize: new google.maps.Size(32, 32)
                    }}
                    position={selectedOrder?.rider.location}
                />
            }
            {/* rider_location added by Bearer Web Developer end*/}
            {p_points &&
                <PolylineF
                    path={p_points}
                    options={{
                        strokeColor: theme.palette.bearer.path,
                        strokeOpacity: 1,
                        strokeWeight: 4
                    }}
                />
            }

            {
                !p_points && pathPreview &&
                <PolylineF
                    path={pathPreview as Location[]}
                    options={{
                        strokeColor: theme.palette.bearer.path,
                        strokeOpacity: 0,
                        icons: [
                            {
                                icon: {
                                    path: "M 0,-1 0,1",
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                    scale: 1,
                                },
                                offset: "0",
                                repeat: "5px",
                            },
                        ]
                    }}
                />
            }
            {/* change current location icon by Bearer Web Developer start */}
            {!p_points &&
                <svg className={styles.currentIcon} onClick={getLocation}
                    xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <circle cx="24" cy="24" r="24" fill="#14438E" />
                    <path d="M22.9998 34.9508V32.9508C20.9165 32.7174 19.129 31.8549 17.6373 30.3633C16.1456 28.8716 15.2831 27.0841 15.0498 25.0008H13.0498V23.0008H15.0498C15.2831 20.9174 16.1456 19.1299 17.6373 17.6383C19.129 16.1466 20.9165 15.2841 22.9998 15.0508V13.0508H24.9998V15.0508C27.0831 15.2841 28.8706 16.1466 30.3623 17.6383C31.854 19.1299 32.7165 20.9174 32.9498 23.0008H34.9498V25.0008H32.9498C32.7165 27.0841 31.854 28.8716 30.3623 30.3633C28.8706 31.8549 27.0831 32.7174 24.9998 32.9508V34.9508H22.9998ZM23.9998 31.0008C25.9331 31.0008 27.5831 30.3174 28.9498 28.9508C30.3165 27.5841 30.9998 25.9341 30.9998 24.0008C30.9998 22.0674 30.3165 20.4174 28.9498 19.0508C27.5831 17.6841 25.9331 17.0008 23.9998 17.0008C22.0665 17.0008 20.4165 17.6841 19.0498 19.0508C17.6831 20.4174 16.9998 22.0674 16.9998 24.0008C16.9998 25.9341 17.6831 27.5841 19.0498 28.9508C20.4165 30.3174 22.0665 31.0008 23.9998 31.0008ZM23.9998 28.0008C22.8998 28.0008 21.9581 27.6091 21.1748 26.8258C20.3915 26.0424 19.9998 25.1008 19.9998 24.0008C19.9998 22.9008 20.3915 21.9591 21.1748 21.1758C21.9581 20.3924 22.8998 20.0008 23.9998 20.0008C25.0998 20.0008 26.0415 20.3924 26.8248 21.1758C27.6081 21.9591 27.9998 22.9008 27.9998 24.0008C27.9998 25.1008 27.6081 26.0424 26.8248 26.8258C26.0415 27.6091 25.0998 28.0008 23.9998 28.0008ZM23.9998 26.0008C24.5498 26.0008 25.0206 25.8049 25.4123 25.4133C25.804 25.0216 25.9998 24.5508 25.9998 24.0008C25.9998 23.4508 25.804 22.9799 25.4123 22.5883C25.0206 22.1966 24.5498 22.0008 23.9998 22.0008C23.4498 22.0008 22.979 22.1966 22.5873 22.5883C22.1956 22.9799 21.9998 23.4508 21.9998 24.0008C21.9998 24.5508 22.1956 25.0216 22.5873 25.4133C22.979 25.8049 23.4498 26.0008 23.9998 26.0008Z" fill="#F5F5F5" />
                </svg>
            }
            {/* change current location icon by Bearer Web Developer end */}
        </GoogleMap>
    );
});

const BearerMap: React.FC = observer(() => {
    return (<BearerMapWrapper><Map /></BearerMapWrapper>)
})

export default BearerMap;



