import React from 'react';
import DestinationEditor from './DestinationEditor';
import DestinationCompact from './DestinationCompact';
import { IDestinationState, IParcelState } from '../../states/app-state';
import { observer } from 'mobx-react-lite';
import styles from './DestinationHandler.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

// export default function DestinationHandler() {
const DestinationHandler: React.FC<{ destination: IDestinationState, parcel: IParcelState }> = observer(({ destination, parcel }) => {

    const { editing } = destination;
    // const editing = true;
    // const data = {
    //     address: 'Melbo St. , Ploto square , Nothingham alley, num 2nd',
    // };

    return (
        <div className={styles.root}>
            {editing && <DestinationEditor destination={destination} parcel={parcel} />}
            {!editing && <DestinationCompact destination={destination} />}
        </div>
    );
});
export default DestinationHandler;