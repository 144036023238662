// this file added by Bearer Web Developer
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, Button } from '@material-ui/core';
import { useAppState } from '../../../states/app-state';
import styles from './popup.module.scss';

const AddToHomeScreenPopup: React.FunctionComponent = observer(() => {

    const appStore = useAppState();
    const { addToHomeScreen } = appStore;

    const hide = () => {
        // handleClick added by Bearer Web Developer start
        if (addToHomeScreen?.handleClick) {
            addToHomeScreen?.handleClick();
        }
        // handleClick added by Bearer Web Developer end
        appStore.setAddToHomeScreen(undefined);
    };
    const cancel = () => {
        appStore.setAddToHomeScreen(undefined);
    };
    if (!addToHomeScreen) {
        return null;
    }
    return (
        <Dialog open={true}  onClose={cancel} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }} >
            <p>{addToHomeScreen?.message}</p>
            <img src="/logo192.png" alt="" />
            <div className={styles.buttons}>
                <Button onClick={cancel} className={styles.cancel}><span>Cancel</span></Button> {/* modified by Bearer Web Developer*/}
                <Button onClick={hide} className={styles.add}><span>{addToHomeScreen?.buttonMessage || "Add"}</span></Button> {/* modified by Bearer Web Developer*/}
            </div>
        </Dialog>
    );
});

export default AddToHomeScreenPopup;
