// this file added by Bearer Web Developer
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, Button } from '@material-ui/core';
import styles from './requestPermissionPopup.module.scss';
import { requestPermission } from '../../api/bearer-api';
import DisabledClickLoading from '../../components/DisabledClickLoading';
import { log } from '../../app/log';

interface Props {
    open: boolean,
    handleClose: () => void
}

const RequestPermissionPopup: React.FunctionComponent<Props> = observer(({ handleClose, open }) => {

    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            await requestPermission();
            setLoading(false);
            handleClose();
        } catch (error) {
            log(error);
            setLoading(false);
            handleClose();
        }
    }
    return (
        <>
            <Dialog open={open} PaperProps={{ className: styles.root, style: { borderRadius: "10px" } }} >
                <div className={styles.main}>
                    <p>Bearer needs to have notifications active to ensure full app functionality.</p>
                    <div className={styles.buttons}>
                        <Button className={styles.button} disableRipple={true} onClick={handleSubmit}>Enable</Button>
                        <Button className={styles.button} disableRipple={true} onClick={handleClose}>Disable</Button>
                    </div>
                    {loading && <DisabledClickLoading />}
                </div>
            </Dialog>
        </>
    );
});

export default RequestPermissionPopup;
