import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import DeleteAccountPopup from "../deleteAccount/DeleteAcountPopup";
import styles from './MoreVertical.module.scss';  // added by Bearer Web Developer
import ChangePasswordPopup from "./changePasswordPopup"; // added by Bearer Web Developer
import { analytics } from "../../api/bearer-api"; // added by Bearer Web Developer
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer


const MoreVertical: React.FC = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };



    const [deleteAccountOpen, setDeleteAccountOpen] = React.useState(false);
    const handleDeleteAccount = () => {
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Delete Account Request
        // App Event Delete Account Clicked
        logEvent(analytics,"delete_account_requested");
        // added by Bearer Web Developer end
        setDeleteAccountOpen(true);
    }
    const handleDeleteAccountClose = () => {
        setDeleteAccountOpen(false);
    }
    // added by Bearer Web Developer start
    const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
    const handleChangePassword = () => {
        setChangePasswordOpen(true);
    }
    const handleChangePasswordClose = () => {
        setChangePasswordOpen(false);
    }
    // added by Bearer Web Developer end
    return (<>
        <DeleteAccountPopup open={deleteAccountOpen} handleClose={handleDeleteAccountClose} />
        <ChangePasswordPopup open={changePasswordOpen} handleClose={handleChangePasswordClose} /> {/* added by Bearer Web Developer */}
        {/* modified by Bearer Web Developer start */}
        <Button onClick={handleMenu} className={styles.more_datails}>
            <span>More Actions</span> <img src={`/expand-more-${open ? "top" : "bottom"}.png`}  />
        </Button>
        {/* modified by Bearer Web Developer end */}
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            anchorReference="anchorEl"
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={handleDeleteAccount}>Delete Account</MenuItem>
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem> {/* added by Bearer Web Developer */}
        </Menu>
    </>
    );
}
export default MoreVertical;