/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './content.module.scss';
import SecretGeneration from './secretGeneration';
import { Button, Link } from '@material-ui/core';
import { useAppState } from '../../../../states/app-state';
import { ApiIntegrationEnum } from '../../../../states/apiIntegrationState/models/apiIntegrationEnum';
import { observer } from 'mobx-react-lite';
import ImportantNote from './importantNote';
import DisabledClickLoading from '../../../DisabledClickLoading';


const Content: React.FC = observer(() => {
    const appState = useAppState();
    const { loadingApi, setLoadingApi } = appState?.ApiIntegration;
    // console.log({ apiIntegrationData: appState?.ApiIntegration?.apiIntegrationData });
    const enableStep = appState?.ApiIntegration?.apiIntegrationStep === ApiIntegrationEnum.EnableApiIntegration;
    const generateStep = appState?.ApiIntegration?.apiIntegrationStep === ApiIntegrationEnum.GenerateApiIntegration;
    const regenerateStep = appState?.ApiIntegration?.apiIntegrationStep === ApiIntegrationEnum.RegenerateApiIntegration;
    const apiDevUrl = appState?.webAppRemoteConfigParameters?.apiDevDocumentsLandingpageUrl; // added by Bearer Web Developer
    const apiAccessTcUrl = appState?.webAppRemoteConfigParameters?.apiAccessTcUrl; // added by Bearer Web Developer
    const privacyPolicy = appState?.webAppRemoteConfigParameters?.privacyPolicy; // added by Bearer Web Developer

    const GenerateAPISecret = async () => {
        if (loadingApi) return;
        if (enableStep && !appState?.ApiIntegration.is_api_access_enabled_by_user) {
            setLoadingApi(true);
            await appState?.ApiIntegration?.fetchEnableApiIntegration();
        } else if (regenerateStep && appState?.ApiIntegration.is_api_access_enabled_by_user) {
            setLoadingApi(true);
            await appState?.ApiIntegration?.fetchRegenerateApiIntegration();
        }
        setLoadingApi(false);
    }
    return (
        <div className={styles.content}>
            <p className={styles.document}>
                Use the API Key and API Secret below to integrate the service on your website/online platform yourself or with Bearer-approved Developers (Dev. Providers).
                <br /><br />
                API Documentation is available on <Link href={apiDevUrl} target="_blank" >API Docs</Link>.
                <br /><br />
                By using the Bearer Business API Service, you agree to <Link href={apiAccessTcUrl} target="_blank" >Bearer's API Terms of Service</Link> and <Link href={privacyPolicy} target="_blank" >Privacy Policy</Link>.
            </p>
            {!enableStep && <SecretGeneration />}
            <ImportantNote />
            <div className={styles.generate_api_secret}>
                {
                    !generateStep &&
                    <Button className={`${styles.generate_api_secret_button} ${generateStep ? styles.regenerate : ''}`}
                        onClick={GenerateAPISecret}>
                        {enableStep ? "Generate a New API Secret" : "Regenerate a New API Secret"}
                    </Button>
                }
            </div>
            {loadingApi && <DisabledClickLoading />}
        </div>
    )
})

export default Content;