// this file added by Bearer Web Developer

import { getEnv, Instance, types, getParentOfType } from "mobx-state-tree";
import { EnvType, OrderState } from "../app-state";

// RiderState added by Bearer Web Developer start
export interface Location {
    lat: number,
    lng: number,
}
export const RiderState = types
    .model("RiderState", {
        location: types.maybeNull(types.frozen<Location>()),
    })
    .views(self => ({
        get riderLocation() {
            return self.location;
        },
        get transportId(): string | undefined {
            return getParentOfType(self, OrderState).transport.value?.transport_id;
        },

    }))
    .actions(self => {
        return {
            setRiderLocation(value: Location | null) {
                self.location = value;
            },
            clear() {
                self.location = null;
            },
            loadRiderLocation() {
                const transportId = self.transportId;
                if (!transportId) {
                    return;
                }
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                const orderState = getParentOfType(self, OrderState);
                api.riderLocation(orderState, transportId);
            },
        }
    })
export interface IRiderState extends Instance<typeof RiderState> { };
// RiderState added by Bearer Web Developer end