/* this file added by Bearer Web Developer  */
import { firebaseApp } from "../../../api/bearer-api";
import { error } from "../../../app/log";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { TConfirmReservedOrderApiIntegrationResponse } from "../models/confirmReservedOrderApiIntegration";


const confirmReservedOrderApiIntegration = async (transport_id: string): Promise<any | void> => {
    try {
        // add the location string as you call getFunctions
        const functions = getFunctions(firebaseApp, 'australia-southeast2');
        const t = httpsCallable<any, TConfirmReservedOrderApiIntegrationResponse>(functions, 'confirmReservedOrderApiIntegration');
        const result = await t({ transport_id });
        // console.log(result)
        return result?.data;
    } catch (err: any) {
        error(err); // modify by Bearer Web Developer
        error(err?.message || 'unknown error');   // throw new Error modified by Bearer Web Developer 
    }
}
export default confirmReservedOrderApiIntegration;