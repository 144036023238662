import React from "react";
import { Button } from "@material-ui/core"
import RightRadioButton from "../../components/rightradiobutton/RightRadioButton";
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './InCompletePaymentNew.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer
enum IncompletePaymentEnum {
    LastCardUsed = "LastCardUsed",
    ChooseAnotherCard = "ChooseAnotherCard",
    CancelOrder = "CancelOrder",
}

const IncompletePayment: React.FC = () => {

    const appState = useAppState();

    const [selected, setSelected] = React.useState<IncompletePaymentEnum | null>(null)
    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        try {
            setLoading(true)
            if (selected === IncompletePaymentEnum.ChooseAnotherCard) {
                appState.selectedOrder?.transport?.setShouldInformServerOnCancel(true);
                appState.selectedOrder?.payment.chooseAnotherPaymentCard();
            } else if (selected === IncompletePaymentEnum.CancelOrder) {
                appState.selectedOrder?.payment.customerCancelTransportBeforePayment();
            } else if (selected === IncompletePaymentEnum.LastCardUsed) {
                window.open(appState.selectedOrder?.payment.dynamicPassword?.actionUrl, '_parent')
            }
        } catch (err) {
            setLoading(false)
        }
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.main_container}>
            <div className={styles.incomplete_payment}>
                <h6>Incomplete Payment</h6>
                <p >The payment for your recent order has not been Finalized yet. Please choose an option to continue:</p>
                <div className={''} >
                    <RightRadioButton
                        label="Finalize using the most recent used card"
                        value={IncompletePaymentEnum.LastCardUsed}
                        checked={selected === IncompletePaymentEnum.LastCardUsed}
                        onClick={() => setSelected(IncompletePaymentEnum.LastCardUsed)}
                    />
                    <RightRadioButton
                        label="Choose another card"
                        value={IncompletePaymentEnum.ChooseAnotherCard}
                        checked={selected === IncompletePaymentEnum.ChooseAnotherCard}
                        onClick={() => setSelected(IncompletePaymentEnum.ChooseAnotherCard)}
                    />
                    <RightRadioButton
                        label="Cancel this order"
                        value={IncompletePaymentEnum.CancelOrder}
                        checked={selected === IncompletePaymentEnum.CancelOrder}
                        onClick={() => setSelected(IncompletePaymentEnum.CancelOrder)}
                    />
                </div>
            </div>
            <div >
                <Button
                    onClick={handleConfirm}
                    disabled={loading}
                    className={styles.button}
                >
                  <span>Confirm</span>  
                </Button>
                {loading && <DisabledClickLoading />}
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
}
export default IncompletePayment;

