/* this file added by Bearer Web Developer  */
import { Button, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styles from './editOrderPopup.module.scss';
import { useAppState } from "../../../../../states/app-state";
import EditOrderData from "./editOrderData";
import ModeOfTransport from "../modeOfTransport";
import ReservationSource from "./reservationSource";
import EditInformation from "./editInformation";
import { observer } from "mobx-react-lite";
import { validateForm } from "./validateForm";
import DisabledClickLoading from "../../../../../components/DisabledClickLoading";

interface IEditOrderPopupProps { open: boolean, handleClose: () => void }
const EditOrderPopup: React.FC<IEditOrderPopupProps> = observer(({ open, handleClose }) => {
    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders.activeManageOrder;
    const editData = appState?.ReservedOrders.editOrderApiIntegrationData;
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const valid = validateForm(appState);
        if (valid) {
            setLoading(true);
            await appState?.ReservedOrders?.editOrderApiIntegration(editData);
            setLoading(false);
        }
    }
    return (
        <Dialog open={open} hideBackdrop={true} onClose={handleClose} PaperProps={{ style: { borderRadius: "10px", maxWidth: "100%", boxShadow: "none" } }}>
            <form className={styles.root} onSubmit={handleSubmit}>
                <div className={styles.header}>
                    <div>
                        <h4>Edit Order {activeManageOrder?.client_order_number}</h4>
                    </div>
                    <Button disableRipple={true} onClick={handleClose}>
                        <img src="/back.png" alt="back" />
                    </Button>
                </div>
                <div className={styles.order_part}>
                    <div className={styles.order_header}>
                        <img src="/confirmation-number.webp" alt="" width={20} height={20} />
                        <span>{activeManageOrder?.business_name || 'Seyco Invoice No.'} : {activeManageOrder?.client_order_number}</span>
                    </div>
                    <EditOrderData />
                    <EditInformation />
                    <div className={styles.made_of_transport_part}>
                        <ModeOfTransport />
                        <ReservationSource />
                    </div>
                </div>
                <div>
                    <Button className={styles.update} type="submit">Update & Confirm</Button>
                </div>
                {loading && <DisabledClickLoading />}
            </form>
        </Dialog>
    )

})
export default EditOrderPopup;