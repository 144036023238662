// this file added by Bearer Web Developer
import React from "react";
interface IMenuDrawerContext {
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
    openDrawer: boolean
    swipe: boolean
}
export const toggleMenuDrawer = (open: boolean, setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setOpenDrawer(open);
    };
export const MenuDrawerContext = React.createContext<IMenuDrawerContext>({ 
    setOpenDrawer: () => {},
    openDrawer: false,
    swipe: false
});