import React from "react";
import { TransportTypeEnum } from "../../states/app-state"

export default function TransportTypeImage(props: { type: TransportTypeEnum }) {
    if (props.type === TransportTypeEnum.riding) {
        return <img src="/motorcycle.png" alt="motorcycle" /> //  modified by Bearer Web Developer
    }
    else if (props.type === TransportTypeEnum.cycling) {
        return <img src="/pedal-bike.png" alt="pedal bike" /> //  modified by Bearer Web Developer
    }
    else if (props.type === TransportTypeEnum.walking) {
        return <img src="/directions-run.png" alt="directions run" /> //  modified by Bearer Web Developer
    }
    return <></>
}
