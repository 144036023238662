import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, Typography } from "@material-ui/core";
import { useAppState } from "../../states/app-state";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
    btnOk: {
        display: 'block',
        textAlign: 'center',
        "&>*": {
            width: '20%',
            color: theme.palette.common.white,
        },
    },
    retryBtn: {
        display: 'block',
        textAlign: 'center',
        "&>*": {
            width: '20%',
            color: theme.palette.common.white,
        },
    },
}));

const CheckServerRunningStates: React.FC = () => {
    const appState = useAppState();
    const [error, setError] = React.useState(false);
    React.useEffect(() => {
        (async () => {
            if (!appState.serverChecked) {
                try {
                    await appState.checkServer();
                } catch (err) {
                    setError(true);
                }
            }
        })();
    }, [appState]);

    return (
        <>
            <NoCloudFunctions />
            <NoWebApp />
            {error && <ServerErrorPopup />}
        </>

    )
}
export default CheckServerRunningStates;

const NoCloudFunctions = observer(() => {
    const classes = useStyles();
    const appState = useAppState();
    const noCloudFunctions = appState.noCloudFunctions;

    const handleClick = () => {
        window.location.replace(noCloudFunctions!.url);
    }

    return (
        <Dialog open={!!noCloudFunctions} PaperProps={{ style: { borderRadius: "10px" } }}>
            <DialogContent>
                <DialogContentText>
                    <Typography>{noCloudFunctions?.text || "Ops, its seems that there is a connectivity issue"}</Typography> {/* modified by Bearer Web Developer */}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.btnOk}>
                {/* modified by Bearer Web Developer start */}
                {noCloudFunctions?.buttonAction &&
                    <Button color="primary" variant="contained" onClick={handleClick}>
                        {noCloudFunctions?.buttonAction}
                    </Button>
                }
                {/* modified by Bearer Web Developer end */}
            </DialogActions>
        </Dialog>

    )
})


const NoWebApp = observer(() => {
    const classes = useStyles();
    const appState = useAppState();
    const noWebApp = appState.noWebApp;

    const handleClick = () => {
        window.location.replace(noWebApp!.url);
    }

    return (
        <Dialog open={!!noWebApp} >
            <DialogContent>
                <DialogContentText>
                    <Typography>{noWebApp?.text}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.btnOk}>
                <Button color="primary" variant="contained" onClick={handleClick}> {noWebApp?.buttonAction}</Button>
            </DialogActions>
        </Dialog>

    )
})



const ServerErrorPopup: React.FC = () => {
    const classes = useStyles();
    const handleClick = () => {
        window.location.reload()
    }
    return (
        <Dialog open={true}>
            <DialogContent>
                <DialogContentText>
                    <Typography>The service is temporarily unavailable, please try later.</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.retryBtn}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClick}
                >
                    Retry
                </Button>
            </DialogActions>
        </Dialog>
    )
}