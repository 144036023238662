import { error, log } from "../app/log"; // added by Bearer Web Developer
import { PlaceType, Location } from "../states/app-state";

let autocompleteService: google.maps.places.AutocompleteService | undefined = undefined;
let geocoder: google.maps.Geocoder | undefined = undefined;

function loadGoogleSdk() {
    if (!(window as any).google) return;
    if (!autocompleteService) {
        // K1: the google sdk is loaded by google-maps-react library in BearerMap file
        autocompleteService = new google.maps.places.AutocompleteService();
        geocoder = new google.maps.Geocoder();
    }
}


function providePredictions(input: string, sessionToken?: google.maps.places.AutocompleteSessionToken): Promise<google.maps.places.AutocompletePrediction[] | undefined> {
    const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-38.608128, 144.572079),
        new google.maps.LatLng(-37.572079, 145.597504)
    );


    return new Promise((resolve, reject) => {
        if (!autocompleteService) resolve(undefined);
        autocompleteService!.getPlacePredictions({ input, sessionToken, bounds, componentRestrictions: { country: "au" } }, (results) => {

            resolve(results as google.maps.places.AutocompletePrediction[]); // modified by Bearer Web Developer
        });

    });
}

function providePlaceDetail(placeId: string): Promise<google.maps.GeocoderResult | undefined> {
    return new Promise((resolve, reject) => {
        if (!geocoder) resolve(undefined);
        geocoder!.geocode({ placeId }, (results) => {
            if (results?.length === 1) { // modified by Bearer Web Developer
                resolve(results[0]);
            }
        });
    });
}



export const googleMap = {
    sessionToken: undefined as (google.maps.places.AutocompleteSessionToken | undefined),
    startSession() {
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    },
    finishSession() {
        this.sessionToken = undefined;
    },
    hasSession() {
        return this.sessionToken !== undefined;
    },

    async fetchAutocomplete(input: string): Promise<PlaceType[]> {
        loadGoogleSdk();
        try {
            const predictions = await providePredictions(input, this.sessionToken);
            return predictions || [];
        } catch (err) {
            error(err); // modify by Bearer Web Developer
            return [];
        }
    },

    async fetchPlaceDetails(placeId: string): Promise<Location | undefined> {
        try {
            const data = await providePlaceDetail(placeId);
            if (data) {
                const location = data.geometry.location;
                return {
                    lat: location.lat(),
                    lng: location.lng(),
                };

            }
        } catch (err) {
            log(err); // modify by Bearer Web Developer
            return;
        }
        return;
    },
}


