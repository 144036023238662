// this file added by Bearer Web Developer (RequestPermissionPopupState)
import { Instance, types } from "mobx-state-tree";
export const RequestPermissionState = types
    .model("RequestPermissionState", {
        open: types.optional(types.boolean, false),
    })
    .actions(self => {
        return {
            setOpen(value: boolean) {
                self.open = value;
            }
        }
    })
export interface IRequestPermissionPopupState extends Instance<typeof RequestPermissionState> { };

