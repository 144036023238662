/* this file added by Bearer Web Developer  */
import React, { useContext } from "react";
import styles from './showSortBasis.module.scss';
import { useAppState } from "../../../states/app-state";
import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";

const ShowSortBasis: React.FC = observer(() => {
    
    const appState = useAppState();
    const handleRemoveSortBasis = () => {
        appState?.ReservedOrders?.setSortBasis(null);
    }
    const sortText = appState?.ReservedOrders?.sortBasis === "creation_time" ? "Creation Time" :'Expiry Time'
    // console.log({SortBasis:appState?.ReservedOrders?.sortBasis })
    return (
        <div className={styles.show_sort_basis}>
            {
                appState?.ReservedOrders?.sortBasis &&
                <Button className={styles.sort} onClick={handleRemoveSortBasis}>Sort by {sortText} X</Button>
            }
        </div>
    )
})

export default ShowSortBasis;