
import React from "react";
import { Typography, Button, Dialog } from "@material-ui/core";
import { observer } from "mobx-react-lite";
// import Lottie from 'react-lottie'; // commented by Bearer Web Developer
// import animationData from '../../assets/animations/Searching.json'; // commented by Bearer Web Developer
import { ISearchRiderState, useAppState, SearchRiderEnum, TransportStateEnum } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './SearchRider.module.scss'; // added by Bearer Web Developer
import { analytics, setDefaultOrigin } from "../../api/bearer-api"; // added by Bearer Web Developer
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer
import ReactPixel from 'react-facebook-pixel'; // added by Bearer Web Developer
import klaviyo from "../../states/klaviyo"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const SearchRider: React.FC = observer(() => {

    const appState = useAppState();
    const { step, notFoundQty } = appState.selectedOrder!.searchRider;
    const dialogOpen = step !== SearchRiderEnum.Searching;
    const transportState = appState.selectedOrder?.transport.step;
    const searchRider = appState.selectedOrder!.searchRider;
    {/* commented by Bearer Web Developer start */ }
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // };
    {/* commented by Bearer Web Developer end  */ }
    React.useEffect(() => {
        if (transportState === TransportStateEnum.FirstTry || transportState === TransportStateEnum.SecondTry) {
            appState.selectedOrder?.searchRider.loadTransportState();
            appState.selectedOrder?.searchRider.setShowCancelButton(); // added by Bearer Web Developer
        }
    }, [transportState, appState, searchRider])


    React.useEffect(() => {
        if (transportState === TransportStateEnum.Authorised) {
            // added by Bearer Web Developer start
            // Firebase Analytics Event Name Order (Payment Authorised, Search Started)
            // App Event getCustomerAppState -> state == Authorised
            logEvent(analytics,"new_order_payment_authorised", {
                transport_id: appState.selectedOrder?.transport.value?.transport_id
            });
            // the fallowing "Purchase" (Facebook Ads default event)
            if (appState?.environmentCode === "PRODUCTION") {
                ReactPixel.trackSingle('3587653761499122', 'Purchase'); // For tracking custom events.
            }
            // added by Bearer Web Developer end
            // added by Bearer Web Developer start
            // klaviyo event start Order (Payment Authorised, Search Started) -> 'Purchase' 
            klaviyo.createEvent({
               email: appState?.profile?.email,
               event: "Purchase"
            });
            // klaviyo event end
            // added by Bearer Web Developer end
            appState.selectedOrder?.searchRider.searchRiderByRealtime();
        }
    }, [transportState, appState])

    const [loadingCancelTransport, setLoadingCancelTransport] = React.useState(false);
    const handleCancelTransportBeforAcceptRider = async () => {
        setLoadingCancelTransport(true);
        await appState.selectedOrder?.searchRider.CustomerCancelTransportBeforeAccept()
        setLoadingCancelTransport(false);
    }
    // added by Bearer Web Developer start
    const searchViewTapped = () => { 
        appState.selectedOrder?.searchRider.increaseSearchViewTappedCount();
    }
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <>
            {/* modified by Bearer Web Developer start */}
            {/* <Lottie
                options={defaultOptions}
                height={400}
                width={400}
            /> */}
            {/* in the following line searchViewTapped added by Bearer Web Developer */}
            <div className={styles.search_box}  onClick={searchViewTapped}>
                <div className={styles.image_part}>
                    <div className={styles.image_box}>
                        <img src="/Searching-Page-(Fast).gif" alt="Searching Page"  />
                    </div>
                </div>
                <div className={styles.searching_text}>Searching for Bearers ....</div>
            </div>
            {/* modified by Bearer Web Developer end */}
            {appState.selectedOrder?.searchRider.showCancelButton &&
                <div className={styles.cancel_box}>
                    <Button
                        onClick={handleCancelTransportBeforAcceptRider}
                        disabled={loadingCancelTransport}
                        disableRipple={true}
                        className={styles.cancel}
                    >
                        <span>Cancel</span>
                    </Button>
                </div>
            }
            {loadingCancelTransport && <DisabledClickLoading />}
            <Dialog open={dialogOpen} PaperProps={{ className: styles.root,style: { borderRadius: "10px" }  }} >
                {step === SearchRiderEnum.RequestIsNotValid && <RequestIsNotValidForSearching searchRider={appState.selectedOrder!.searchRider} />}
                {step === SearchRiderEnum.CanceledByCustomer && <CanceledByCustomer searchRider={appState.selectedOrder!.searchRider} />}
                {step === SearchRiderEnum.RidersNotFound && notFoundQty < 2 && <RidersNotFound searchRider={appState.selectedOrder!.searchRider} />}
                {step === SearchRiderEnum.CallSupport && <CallSupport searchRider={appState.selectedOrder!.searchRider} />}
                {step === SearchRiderEnum.RiderNotFoundAfterTwoSearch && <RiderNotFoundAfterTwoSearch searchRider={appState.selectedOrder!.searchRider} />}

            </Dialog >
        </>
    )
    // modified by Bearer Web Developer end
})
export default SearchRider;



const RequestIsNotValidForSearching: React.FC<{ searchRider: ISearchRiderState }> = observer(({ searchRider }) => {
    const handleRequestIsNotValidForSearching = () => {
        searchRider.order!.clear();
        searchRider.clear();
    }
    return (
        <>
            <Typography>Request is not valid</Typography>
            <Button onClick={handleRequestIsNotValidForSearching}>Ok</Button>
        </>
    )
})

const CanceledByCustomer: React.FC<{ searchRider: ISearchRiderState }> = observer(({ searchRider }) => {
    const handleCanceledByCustomer = () => {
        searchRider.order!.clear();
        searchRider.clear();
    }
    return (
        <>
            <Typography>This Transport Canceled by Customer</Typography>
            <Button onClick={handleCanceledByCustomer}>OK</Button>
        </>
    )
})


const RiderNotFoundAfterTwoSearch: React.FC<{ searchRider: ISearchRiderState }> = observer(({ searchRider }) => {
    const handleConfirm = () => {
        searchRider.order!.clear();
        searchRider.clear();
        setDefaultOrigin();
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.rider_not_found_after_two_search}>
            <h3>Oops!</h3>
            <p>Unfortunately no riders found for this job!</p>
            <Button className={styles.button} onClick={handleConfirm}>Ok</Button>
        </div>
    )
    // modified by Bearer Web Developer end
})

const RidersNotFound: React.FC<{ searchRider: ISearchRiderState }> = observer(({ searchRider }) => {
    const appState = useAppState();

    const [loadingSearchRider, setLoadingSearchRider] = React.useState(false);
    const handleSearchRider = async () => {
        appState.selectedOrder?.searchRider.setClose();
        setLoadingSearchRider(true);
        await appState.selectedOrder?.searchRider.searchRiderByRealtime();
        setLoadingSearchRider(false);
    }

    const [loadingCancel, setLoadingCancel] = React.useState(false);
    const handleCancelByCustomer = async () => {
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Search Area Clicked During the Search
        // when RiderFound or RidersNotFound it is sent
        logEvent(analytics,"search_view_tapped", {
            clicks_count_on_search_view: appState.selectedOrder?.searchRider?.searchViewTappedCount
        })
        // added by Bearer Web Developer end
        setLoadingCancel(true);
        await appState.selectedOrder?.searchRider.CustomerCancelTransportBeforeAccept()
        setLoadingCancel(false);
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.rider_not_found}>
            <p>We couldn’t find a Bearer accepting your request. Do you want us to have another look again?</p>
            <div className={styles.confirm}>
                    <Button
                       className={styles.yes}
                        onClick={handleSearchRider}
                        disabled={loadingSearchRider}
                    >
                        Yes
                    </Button>
                    {loadingSearchRider && <DisabledClickLoading />}
                    <Button
                        className={styles.no}
                        onClick={handleCancelByCustomer}
                        disabled={loadingCancel}
                    >
                        No
                    </Button>
                    {loadingCancel && <DisabledClickLoading />}
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
})

const CallSupport: React.FC<{ searchRider: ISearchRiderState }> = ({ searchRider }) => {
    const appState = useAppState();
    const handleOk = () => {
        appState.selectedOrder?.searchRider.setOpen();
    }
    return (
        <>
            <Typography>This Transport has been accepted by a Rider!</Typography>
            <Button onClick={handleOk}>OK</Button>

        </>
    )
}



