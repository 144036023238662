/* this file added by Bearer Web Developer  */
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import styles from './locations.module.scss';
import { useAppState } from '../../../../../../states/app-state';

const Locations: React.FC = () => {
   
    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders.activeManageOrder;
    return (
        <div className={styles.locations}>
            <Timeline classes={{ root: styles.time_line }}>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content }}>
                    <TimelineSeparator>
                        <img src="/origin.png" alt="origin" />
                        <TimelineConnector className={''} />
                    </TimelineSeparator>
                    <TimelineContent classes={{ root: styles.timeline_content_root }}>
                        <div className={styles.timeline_content}>
                            <p>{activeManageOrder?.origin_address}</p>
                            <span>{activeManageOrder?.origin_details}</span>
                        </div>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content, root: styles.timelineItem_root }}>
                    <TimelineSeparator >
                        <img src="/destination.png" alt="destination" />
                    </TimelineSeparator>
                    <TimelineContent classes={{ root: styles.timeline_content_root }}>
                        <div className={styles.timeline_content}>
                            <p>{activeManageOrder?.destination_address}</p>
                            <span>{activeManageOrder?.destination_details}</span>
                        </div>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    )
}
export default Locations;