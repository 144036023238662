// this file added by Bearer Web Developer
import { getRemoteConfigData } from "../../api/bearer-api";
interface IKlaviyoProfileData {
    type: 'profile',
    attributes: {
        email: string,
        properties: {
            external_id?: string,
            last_open?: string, // Today FORMAT YYYY-MM-DD
            platform_type?: "web",
            phone_number?: string,
            first_name?: string,
            last_name?: string,
        }
    }
}
interface IKlaviyoEventData {
    event: string,
    email: string,
}
const createOrUpdateProfile = async (data: IKlaviyoProfileData) => {

    const { klaviyoApiPublicKey } = await getRemoteConfigData();
    const url = `https://a.klaviyo.com/client/profiles/?company_id=${klaviyoApiPublicKey}`;
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            revision: '2024-07-15',
            'content-type': 'application/json'
        },
        body: JSON.stringify({ data: data })
    };

    await fetch(url, options)
        .then(res => {
            // console.log(res);
        })
        .catch(err => {
            // console.error('error:' + err)
        });
}

const createEvent = async (data: IKlaviyoEventData) => {
    const { event, email } = data;
    const { klaviyoApiPublicKey } = await getRemoteConfigData();
    const url = `https://a.klaviyo.com/client/events/?company_id=${klaviyoApiPublicKey}`;
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            revision: '2024-07-15',
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                type: 'event',
                attributes: {
                    properties: {},
                    metric: { data: { type: 'metric', attributes: { name: event } } },
                    profile: {
                        data: {
                            type: 'profile', attributes: { email: email, properties: {}, }
                        }
                    }
                }
            }
        })
    };

    await fetch(url, options)
        .then(res => {
            // console.log(res);
        })
        .catch(err => {
            // console.error('error:' + err)
        });
}

const klaviyo = { createOrUpdateProfile, createEvent };
export default klaviyo;



