import React from 'react';
import OriginEditor from './OriginEditor';
import OriginCompact from './OriginCompact';
import { IDestinationState, IOriginState } from '../../states/app-state';
import { observer } from 'mobx-react-lite';
import styles from './OriginHandler.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const OriginHandler: React.FC<{ origin: IOriginState, destination: IDestinationState }> = observer(({ origin, destination }) => {

    const { editing } = origin;
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            {editing && <OriginEditor origin={origin} destination={destination} />}
            {!editing && <OriginCompact origin={origin} />}
        </div>
    );
    // modified by Bearer Web Developer end
});

export default OriginHandler;