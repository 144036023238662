import React from "react";
import { CircularProgress, makeStyles, Theme } from "@material-ui/core";
import { fetchDownloadUrl } from "../../api/bearer-api";
import styles from './ShowImage.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const ShowImage = ({ fireBaseUrl }: { fireBaseUrl: string }) => {

    const [url, setUrl] = React.useState("");

    React.useEffect(() => {
        (async () => {
            const result = await fetchDownloadUrl(fireBaseUrl);
            if (result) {
                setUrl(result);
            }
        })();
    }, [url, fireBaseUrl])

    return <>
        {url && <img alt="" src={url} width={54} height={54} className={styles.image} />}
        {!url && <CircularProgress />}
    </>
}
export default ShowImage;