/* this file added by Bearer Web Developer  */
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import styles from './locations.module.scss';
import BearerTextField from '../../../../../../../components/fields/BearerTextField';
import { useAppState } from '../../../../../../../states/app-state';
import { observer } from 'mobx-react-lite';
import { DestinationDetails } from '../../validateForm';


const Locations: React.FC = observer(() => {


    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders.activeManageOrder;
    const editData = appState?.ReservedOrders.editOrderApiIntegrationData;
    const { setEditOrderApiIntegrationData } = appState?.ReservedOrders;
    return (
        <div className={styles.locations}>
            <Timeline classes={{ root: styles.time_line }}>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content }}>
                    <TimelineSeparator>
                        <img src="/origin.png" alt="origin" />
                        <TimelineConnector className={''} />
                    </TimelineSeparator>
                    <TimelineContent classes={{ root: styles.timeline_content_root }}>
                        <div className={styles.timeline_content}>
                            <p>{activeManageOrder?.origin_address}</p>
                            <span>{activeManageOrder?.origin_details}</span>
                        </div>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content, root: styles.timelineItem_root }}>
                    <TimelineSeparator >
                        <img src="/destination.png" alt="destination" />
                    </TimelineSeparator>
                    <div>
                        <TimelineContent classes={{ root: styles.timeline_content_root }}>
                            <div className={styles.timeline_content}>
                                <p>{activeManageOrder?.destination_address}</p>
                            </div>
                        </TimelineContent>
                        <div className={styles.destination_edit}>
                            <BearerTextField
                                colorStyle='gray'
                                placeholder={"Destination Details"}
                                label="Destination Details"
                                value={editData.destination_details}
                                onChange={(e) => setEditOrderApiIntegrationData({ destination_details: e.target.value })}
                                clear={() => { setEditOrderApiIntegrationData({ destination_details: '' }) }}
                                validator={DestinationDetails}
                            />
                        </div>
                    </div>
                </TimelineItem>
            </Timeline>
        </div>
    )
})
export default Locations;