// this component added by Bearer Web Developer
import React from 'react'
import { useLoadScript} from "@react-google-maps/api";

const BearerMapWrapper : React.FC<{children: React.ReactNode}> = ({children})=>{

    type Libraries = ("places" | "drawing" | "geometry" | "localContext" | "visualization")[];
    const [libraries] = React.useState<Libraries>(['places']);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPAPI || "",
        libraries: libraries,
        // ...otherOptions
    });
    if (!isLoaded) return (<div>loading...</div>);
    if (loadError) {
        console.error('load Error google Maps');
        return <span></span>;
    }

    return (<>{children}</>)
}

export default BearerMapWrapper;