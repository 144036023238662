import { TCardInformation } from "../../api/bearer-api";
import Amex from "../../assets/images/Amex.png";
import DinersClub from "../../assets/images/DinersClub.png";
import Discover from "../../assets/images/Discover.png";
import Jcb from "../../assets/images/Jcb.png";
import MasterCard from "../../assets/images/MasterCard.png";
import UnionPay from "../../assets/images/UnionPay.png";

function getImageOfCart(cardType: TCardInformation): any {
    if (cardType.brand === "American Express") {
        return <img src={Amex} alt="" style={{ width: 45 }} />
    }
    else if (cardType.brand === "Visa") {
        // modified by Bearer Web Developer start
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="62" height="18" viewBox="0 0 62 18" fill="none" style={{ width: 45 }}>
                <g clip-path="url(#clip0_4206_6561)">
                    <path d="M45.1399 0.346342C43.6917 -0.174531 42.161 -0.435908 40.6197 -0.425481C35.6354 -0.425481 32.1152 2.05297 32.0903 5.61074C32.0592 8.22449 34.5918 9.69742 36.5077 10.5707C38.4733 11.4686 39.1306 12.0344 39.1244 12.837C39.112 14.0578 37.5544 14.6113 36.1058 14.6113C34.1152 14.6113 33.028 14.3376 31.3551 13.6519L30.7352 13.3659L30.028 17.4618C31.2399 17.9599 33.4236 18.3874 35.679 18.4243C40.978 18.4243 44.4421 15.9612 44.4826 12.1759C44.5262 10.0879 43.1555 8.51047 40.2708 7.20667C38.517 6.36104 37.4298 5.79524 37.4298 4.93732C37.4298 4.17779 38.3612 3.36599 40.3082 3.36599C41.604 3.33505 42.8918 3.57502 44.0869 4.07017L44.5542 4.27619L45.2614 0.318668L45.1399 0.346342ZM58.0742 -0.0933818H54.1802C52.9684 -0.0933818 52.0556 0.235643 51.526 1.42259L44.0371 18.169H49.336L50.3952 15.4231L56.8623 15.4292C57.015 16.0719 57.4823 18.169 57.4823 18.169H62.1551L58.0742 -0.0933818ZM24.9222 -0.247132H29.972L26.8132 18.0245H21.7697L24.9222 -0.253282V-0.247132ZM12.1032 9.82349L12.6266 12.3604L17.561 -0.0933818H22.9098L14.963 18.1444H9.62663L5.26535 2.70179C5.19472 2.44716 5.02883 2.22846 4.80119 2.08987C3.22926 1.28833 1.56371 0.680066 -0.158203 0.278691L-0.089669 -0.105684H8.03788C9.14066 -0.0626338 10.0285 0.278692 10.3244 1.44104L12.1001 9.83272L12.1032 9.82349ZM51.8438 11.6869L53.8562 6.57629C53.8313 6.63164 54.2705 5.52157 54.526 4.83584L54.8717 6.41332L56.0399 11.6839H51.8407L51.8438 11.6869Z" fill="#BDBDBD" />
                </g>
                <defs>
                    <clipPath id="clip0_4206_6561">
                        <rect width="62" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
        // modified by Bearer Web Developer end
    }
    else if (cardType.brand === "MasterCard") {
        return <img src={MasterCard} alt="" style={{ width: 45 }} />
    }
    else if (cardType.brand === "DinersClub") {
        return <img src={DinersClub} alt="" style={{ width: 45 }} />
    }
    else if (cardType.brand === "Discover") {
        return <img src={Discover} alt="" style={{ width: 45 }} />
    }
    else if (cardType.brand === "UnionPay") {
        return <img src={UnionPay} alt="" style={{ width: 45 }} />
    }
    else if (cardType.brand === "JCB") {
        return <img src={Jcb} alt="" style={{ width: 45 }} />
    }
}

export default getImageOfCart;