import React from "react";
import { observer } from "mobx-react-lite";
import { Avatar, Button } from "@material-ui/core";
import { useEffect } from "react";
import { useAppState } from "../../states/app-state";
import { fetchDownloadUrl } from "../../api/bearer-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from './AvatarEditor.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

export function readFile(file: File): Promise<string | null> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener(
            "load",
            () => resolve(reader.result as string),
            false
        );
        reader.readAsDataURL(file);
    });
}


const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });


export async function compressImage(
    imageSrc: string,
): Promise<Blob | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    const imageType = 'image/JPEG';

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(file);
        }, imageType, 0.9);
    });
}

// added by Bearer Web Developer start
interface IAvatarEditor {
    fileInputRef: React.RefObject<HTMLInputElement>
    preview: string
    setPreview: React.Dispatch<React.SetStateAction<string>>
}
// added by Bearer Web Developer end
// these props added by Bearer Web Developer
const AvatarEditor: React.FC<IAvatarEditor> = observer(({fileInputRef,preview, setPreview}) => {
    const appState = useAppState();
    // const fileInputRef = useRef<HTMLInputElement>(null); // commented by Bearer Web Developer (move to father component)
    // const [preview, setPreview] = React.useState("") // commented by Bearer Web Developer (move to father component)
    const [loading, setLoading] = React.useState<boolean>(false) // added by Bearer Web Developer

    const uploadProfileImage = async (file: File) => {
        // added by Bearer Web Developer start
        setLoading(true);
        const image = await readFile(file);
        if (!image) return;
        const compressedImage = await compressImage(image);
        if (!compressedImage) {
            setLoading(false);
            return;
        } else {
            if (appState.profile.avatar) appState.profile.deleteProfileImage();
        };
        await appState.profile.updateAvatar(compressedImage);
        setLoading(false);
        // added by Bearer Web Developer end
    }


    useEffect(() => {
        const image = appState.profile.avatar;
        if (image) {
            (async () => {
                setLoading(true); // added by Bearer Web Developer
                const result = await fetchDownloadUrl(image);
                setLoading(false); // added by Bearer Web Developer
                if (result) {
                    setPreview(result);
                }
            })();
        } else {
            setPreview('');
        }
    }, [setPreview, appState.profile.avatar])

// modified by Bearer Web Developer start
// move handleDeleteImage function to father component
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <Button
                    className={''}
                    onClick={(event) => {
                        event.preventDefault();
                        fileInputRef.current?.click()
                    }}
                >
                    {/* add Spinner while uploading the Avatar Photo by Bearer Web Developer start */}
                    {loading && <CircularProgress />}
                    {!preview && !loading && <img src='/avatar.png' className={styles.avatar} />}
                    {/* add Spinner while uploading the Avatar Photo by Bearer Web Developer end */}
                    {preview && <Avatar src={preview} className={styles.avatar_person} />}
                </Button>
                <input
                    type="file"
                    className={styles.input}
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={(event: any) => {
                        const file = event.target.files[0];
                        if (file && file.type.substr(0, 5) === 'image') {
                            uploadProfileImage(file);
                        }
                    }}
                />
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
})
export default AvatarEditor;
