import React from 'react';
import ParcelEditor from './ParcelEditor';
import ParcelCompact from './ParcelCompact';
import { IParcelState, ITransportState } from '../../states/app-state';
import { observer } from 'mobx-react-lite';
// useStyles removed by Bearer Web Developer
const ParcelHandler: React.FC<{ parcel: IParcelState, transport: ITransportState }> = observer(({ parcel, transport }) => {

    const { editing } = parcel;


    return (
        <div className={''}>
            {editing && <ParcelEditor parcel={parcel} transport={transport} />}
            {!editing && <ParcelCompact parcel={parcel} />}
        </div>
    );
});
export default ParcelHandler
