// this file added by Bearer Web Developer
import React, { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import { Observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            padding: theme.spacing(1),
        },
    },
    btnConfirm: {
        width: '100%',
        color: theme.palette.common.white,
    },
    text: {
        marginTop: "30px",
        marginBottom: "30px",
    }
}),
);

const IpAlertMessage: React.FC = () => {

    const classes = useStyles();
    const appState = useAppState();
    const [open, setOpen] = useState<boolean>(true);
    const handleConfirm = () => {
        window.location.reload();
        setOpen(false);
        appState.profile.ipAlertMessage = false;
    }

    return (
        <Dialog
            className={classes.dialogContainer} PaperProps={{ style: { borderRadius: "10px" } }}
            open={open}
            maxWidth="sm"
        >
            <DialogContent>
                <DialogContentText>
                    <div className={classes.text}>
                    For legal purposes, during the registration process,
                    we need to have access to your IP address. If necessary,
                    please turn off anti-virus software and try again.
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Observer>{() => {

                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                            disabled={false}
                            className={classes.btnConfirm}
                        >
                            Agree
                        </Button>
                    )
                }}</Observer>
            </DialogActions>
        </Dialog>
    )
}

export default IpAlertMessage;