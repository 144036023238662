// this file added by Bearer Web Developer (FreeOrderState)
import { Instance, types } from "mobx-state-tree";
export const FreeOrderState = types
    .model("FreeOrderState", {
        open: types.optional(types.boolean, false),
    })
    .actions(self => {
        return {
            setOpen(value: boolean) {
                self.open = value;
            }
        }
    })
export interface IFreeOrderState extends Instance<typeof FreeOrderState> { };

