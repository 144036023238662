import React from "react";
import { FormControlLabel, Radio, RadioProps } from "@material-ui/core";
import styles from './rightradiobutton.module.scss'; // added by Bearer Web Developer

type IRightRadioButtonProps = RadioProps & {
    label: string;
    gray?: boolean
}
const RightRadioButton: React.FC<IRightRadioButtonProps> = ({ label, value,gray, ...props }) => {
    // modified by Bearer Web Developer start (create custom radio button)
    return (
        <FormControlLabel 
            value={value}
            control={<Radio disableRipple={true} {...props}
                icon={<div className={gray ? styles.icon_gray : styles.icon_blue}></div>}
                checkedIcon={<div className={gray ? styles.checked_icon_gray :styles.checked_icon_blue}><span></span></div>}
            />}
            label={<div className={gray ? styles.label_gray : styles.label_blue}>{label}</div>}
        />
    );
    // modified by Bearer Web Developer end
}
export default RightRadioButton;
