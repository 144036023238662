import React from 'react';
import TransportEditor from './TransportsEditor';
import TransportsCompact from './TransportsCompact';
import { IOrderState, ITransportState } from '../../states/app-state';
import { observer } from 'mobx-react-lite';
// useStyles removed by Bearer Web Developer


const TransporstHandler: React.FC<{ transport: ITransportState, selectedOrder: IOrderState }> = observer(({ transport, selectedOrder }) => {

    const { editing } = transport;


    return (
        <div>
            {editing && <TransportEditor transport={transport} />}
            {!editing && <TransportsCompact transport={transport} selectedOrder={selectedOrder} />}
        </div>
    );
});
export default TransporstHandler;
