/* this file added by Bearer Web Developer  */
import { Button } from "@material-ui/core";
import styles from './management.module.scss';
import React, { useContext } from "react";
import { useAppState } from "../../../../../states/app-state";
import { ReservedOrderInfoContext } from "../contexts/reservedOrderInfo";
import { observer } from "mobx-react-lite";

const Management: React.FC = observer(() => {
    
    const reservedOrderInfo = useContext(ReservedOrderInfoContext);
    const appState = useAppState();
    const OpenManageOrder = () => {
        appState?.ReservedOrders?.setActiveManageOrder(reservedOrderInfo);
    }
    // console.log({ activeManageOrder })
    return (
        <div className={styles.management}>
            <Button className={styles.management_button} onClick={OpenManageOrder}>
                <span>Manage the order</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.3746 12L9.37461 18L7.97461 16.6L12.5746 12L7.97461 7.4L9.37461 6L15.3746 12Z" fill="#14438E" />
                </svg>
            </Button>
        </div>
    )
})

export default Management;