import React from "react";
import { ITransportMessageState, TransportStateEnum } from "../../states/app-state";
import styles from './StateOfTransport.module.scss';

function StateOfTransport({ transportMessage }: { transportMessage: ITransportMessageState }) {

    return (
        <>
            {/* modify the fallowing transportMessage.messageText to transportMessage.messageType for TransportStateEnum.RiderOnTheWayToOrigin by Bearer Web Developer */}
            {transportMessage.messageType === TransportStateEnum.RiderOnTheWayToOrigin && <RiderOnTheWayToOrigin transportMessage={transportMessage} />}
            {transportMessage.messageType === TransportStateEnum.RiderArrivesToOrigin && <RiderArrivedToOrigin transportMessage={transportMessage} />}
            {transportMessage.messageType === TransportStateEnum.RiderPickupParcel && <RiderPickedUpParcel transportMessage={transportMessage} />}
            {transportMessage.messageType === TransportStateEnum.RiderArrivesToDestination && <RiderArrivedToDestination transportMessage={transportMessage} />}
            {transportMessage.messageType === TransportStateEnum.RiderGetsPermissionToDropOff && <RiderGetsPermissionToDropOff transportMessage={transportMessage} />}
        </>
    )
}

export default StateOfTransport;


function RiderOnTheWayToOrigin({ transportMessage }: { transportMessage: ITransportMessageState }) {
    const messageText = transportMessage.messageText;
    return (
        <div className={styles.message_text}>
            <img src="trip-origin.png" alt="" />
            <p>{messageText}</p>
        </div>
    )
}

function RiderArrivedToOrigin({ transportMessage }: { transportMessage: ITransportMessageState }) {
    const messageText = transportMessage.messageText;
    return (
        <div className={styles.message_text}>
            <img src="trending-flat.png" alt="" />
            <p>{messageText}</p>
        </div>
    )
}
function RiderPickedUpParcel({ transportMessage }: { transportMessage: ITransportMessageState }) {
    const messageText = transportMessage.messageText;
    return (
        <div className={styles.message_text}>
            <img src="trending-flat.png" alt="" />
            <p>{messageText}</p>
        </div>
    )
}
function RiderArrivedToDestination({ transportMessage }: { transportMessage: ITransportMessageState }) {
    const messageText = transportMessage.messageText;
    return (
        <div className={styles.message_text}>
            <img src="crop-square.png" alt="" />
            <p>{messageText}</p>
        </div>
    )
}
function RiderGetsPermissionToDropOff({ transportMessage }: { transportMessage: ITransportMessageState }) {
    const messageText = transportMessage.messageText;
    return (
        <div className={styles.message_text}>
            <img src="done.png" alt="" />
            <p>{messageText}</p>
        </div>
    )
}



