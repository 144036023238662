import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import { useAppState } from "../../states/app-state";
import StipePayment from "./StripePayment";
import DeleteIcon from '@material-ui/icons/Delete';
import { TCardInformation } from "../../api/bearer-api";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import getImageOfCart from "./ImageOfCard";


const useStyles = makeStyles((theme: Theme) => ({
    selectedCard: {
        backgroundColor: '#009eff',
    },
    cardsContainer: {
        border: '1px solid #e0e0e0',
    },
    expireCard: {
        backgroundColor: theme.palette.error.main,
        textAlign: 'center',
        "&>*": {
            color: theme.palette.common.white,
        }
    },
}),
);


interface IPaymentMethodPopupProps { open: boolean, handleClose: () => void }
const PaymentMethodPopup: React.FC<IPaymentMethodPopupProps> = ({ open, handleClose }) => {
    return (
        <Dialog open={open} PaperProps={{ style: { borderRadius: "10px" } }}>
            <DialogTitle>
                <IconButton onClick={handleClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ShowCards />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}
export default PaymentMethodPopup;




const ShowCards: React.FC = observer(() => {
    const appState = useAppState();
    const { cardOptions } = appState.selectedOrder!.payment;
    React.useEffect(() => {
        appState.selectedOrder?.payment.prepareCardOptions(false);
    }, [appState]);


    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Please choose a payment option</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StipePayment />
                </AccordionDetails>
            </Accordion>
            {cardOptions?.map((cardOption, i) => <ShowCard key={cardOption.id} cardInformation={cardOption} withDivider={i !== 0} />)}
            {!cardOptions && <CircularProgress />}

        </>
    )
})

const ShowCard: React.FC<{ cardInformation: TCardInformation, withDivider: boolean }> = observer((({ cardInformation }) => {
    const appState = useAppState();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const handleConfirmRemoveCard = async () => {
        try {
            setLoading(true);
            await appState.selectedOrder?.payment.removeCard(cardInformation.id);
        } catch (err) {
            setLoading(false);
        }
    }


    const selected = cardInformation === appState.selectedOrder?.payment.selectedCard;

    const expMonth = cardInformation.exp_month;
    const expYear = cardInformation.exp_year * 12;
    const expired = expMonth + expYear

    const year = new Date().getFullYear() * 12;
    const month = new Date().getMonth() + 1;
    const now = year + month;



    return (
        <ListItem button selected={selected} className={classes.cardsContainer}>
            <ListItemText>
                {getImageOfCart(cardInformation)}
            </ListItemText>
            <ListItemText> XXXX {cardInformation.last4}</ListItemText>
            <ListItemText>{cardInformation.exp_month}/{cardInformation.exp_year}</ListItemText>
            {expired < now &&
                <ListItemText className={classes.expireCard}>Expire</ListItemText>
            }
            <Button
                onClick={handleConfirmRemoveCard}
                disabled={loading}
            >
                <DeleteIcon color="secondary" />
            </Button>
            {loading && <DisabledClickLoading />}
        </ListItem>

    );
}));


