import { observer } from "mobx-react-lite";
import React from "react";
import { TransportStateEnum, useAppState } from "../../states/app-state";
import IncompletePayment from "../price/InCompletePaymentNew";
import OrderForm from "./OrderForm";
import OrderHandler from "./OrderHandler";
import SearchRider from "./SearchRider";
import TransportRate from "./TransportRate";


const LeftSidePanel: React.FC = observer(() => {
    const appState = useAppState();
    const order = appState.selectedOrder;
    const riderInfo = order?.transport.riderInfo;
    const searchRiderOpen = order?.searchRider.open;
    const step = order?.transport.step;
    // added by Bearer Web Developer start, move from TransportProgress child component cause of rerendering and multi realtime 
    React.useEffect(() => {
        appState.selectedOrder?.searchRider.loadTransportState();
    }, [appState, appState.selectedOrder?.searchRider]);
    // added by Bearer Web Developer end
    if (step === TransportStateEnum.RiderCompletedTransport) {
        return <TransportRate />
    }
    else if (riderInfo) {
        return <OrderHandler />
    } else if (searchRiderOpen) {
        return <SearchRider />
    } else if (step === TransportStateEnum.ProcessingPayment) {
        return <IncompletePayment />
    } else {
        return <OrderForm />
    }
})

export default LeftSidePanel;
