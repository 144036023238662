
import { Tab, Tabs } from "@material-ui/core";
import React from "react";
import OrderForm from "./OrderForm";
import TransportProgress from "./TransportProgress";
import styles from './OrderHandler.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        // id={`wrapped-tabpanel-${index}`}
        // aria-labelledby={`wrapped-tab-${index}`}
        // {...other}
        >
            {value === index && (
                <>{children}</>

            )}
        </div>
    )
}



const OrderHandler: React.FC = () => {

    const [value, setValue] = React.useState('two');
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                classes={{ flexContainer: styles.flex_container }}
                className={styles.tabs}
                TabIndicatorProps={{ hidden: true }}
            >
                <Tab style={{ margin: 0, padding: 0, textTransform: "none", minWidth:"100%"}}
                    icon={<div className={styles.tab_more}>More details</div>} value="one" />
                <Tab style={{ margin: 0, padding: 0, textTransform: "none", minWidth:"100%"}}
                    icon={<div className={styles.tab_info}>Transport Info</div>} value="two" />
            </Tabs>
            <TabPanel value={value} index="one">
                <OrderForm />
            </TabPanel>
            <TabPanel value={value} index="two">
                <TransportProgress />
            </TabPanel>

        </div>
    )
    // modified by Bearer Web Developer end
}
export default OrderHandler;

