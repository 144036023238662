// this file added by Bearer Web Developer
import { TReservedOrderDetails } from "./reservedOrderDetails";
export enum ApprovalMethodEnum {
    Code = 'Code',
    None = 'None',
}

export type TEditOrderApiIntegrationError = {
    status: 'Client Error' | 'Server Error',
    code: 499 | 401 | 402 | 410 | 422 | 421 | 500 | 400, // 400 added by myself (it is not in the logic that has been sent)
    message: string,
}
export type TEditOrderApiIntegrationSuccess = {
    status: 'Successful',
    code: 200,
    message: ' The Transport has been successfully Updated.',
    new_transport: TReservedOrderDetails
}
export type TEditOrderApiIntegrationResponse = TEditOrderApiIntegrationSuccess | TEditOrderApiIntegrationError;

export type TEditOrderApiIntegrationData = {
    transport_id: string;
    destination_details: string;
    recipient_name: string;
    recipient_phone_number: string;
    dropoff_verification_method: ApprovalMethodEnum,
}
export type TEditOrderApiIntegrationDataValue = {
    transport_id?: string;
    destination_details?: string;
    recipient_name?: string;
    recipient_phone_number?: string;
    dropoff_verification_method?: ApprovalMethodEnum,
}
export const initialEditOrderApiIntegrationData : TEditOrderApiIntegrationData  = {
    transport_id: '',
    destination_details: '',
    recipient_name: '',
    recipient_phone_number: '',
    dropoff_verification_method: ApprovalMethodEnum.None,
}