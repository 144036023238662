import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, Theme, Typography } from "@material-ui/core";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import { useAppState } from "../../states/app-state";

const useStyles = makeStyles((theme: Theme) => ({
    actions: {
        width: '50%',
        color: theme.palette.common.white,
    }
}),
);


interface IAllowCancellationTransportBeforePaymentProps { open: boolean, handleClose: () => void }
const AllowCancellationTransportBeforePayment: React.FC<IAllowCancellationTransportBeforePaymentProps> = ({ open, handleClose }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const appState = useAppState();
    const handleCancel = async () => {
        try {
            setLoading(true);
            await appState.selectedOrder?.payment.customerCancelTransportBeforePayment();
            handleClose();
        } catch (err) {
            setLoading(false);
        }
    }
    return (
        <Dialog open={open} PaperProps={{ style: { borderRadius: "10px" } }}>
            <DialogContent>
                <DialogContentText>
                    <Typography>Are you sure you want to cancel this order?</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions >

                <Button
                    color='primary'
                    variant="contained"
                    onClick={handleClose}
                    className={classes.actions}
                >
                    No
                </Button>
                <Button
                    color='primary'
                    variant="contained"
                    onClick={handleCancel}
                    disabled={loading}
                    className={classes.actions}
                >
                    yes
                </Button>
                {loading && <DisabledClickLoading />}
            </DialogActions>
        </Dialog>
    )
}
export default AllowCancellationTransportBeforePayment;



