/* this file added by Bearer Web Developer  */
import { Button, Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styles from './manageOrderPopup.module.scss';
import Information from "./information";
import ModeOfTransport from "./modeOfTransport";
import ReservationSource from "./reservationSource";
import Delete from "./delete";
import OrderData from "./orderData";
import EditOrderPopup from "./editOrderPopup";
import { ApprovalMethodEnum, initialEditOrderApiIntegrationData } from "../../../../states/reservedOrdersState/models/editOrderApiIntegration";
import { useAppState } from "../../../../states/app-state";
import setReservedOrder from "../../../../states/reservedOrdersState/setReservedOrder";
import { fetchDownloadUrl } from "../../../../api/bearer-api";
import DisabledClickLoading from "../../../../components/DisabledClickLoading";

interface IManageOrderPopupProps { open: boolean, handleClose: () => void }
const ManageOrderPopup: React.FC<IManageOrderPopupProps> = ({ open, handleClose }) => {
    const appState = useAppState();
    const activeManageOrder = appState?.ReservedOrders.activeManageOrder;
    const [editOrder, setEditOrder] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleEdit = (bool: boolean) => {
        if (bool) {
            // set initial edit data values
            appState?.ReservedOrders?.setEditOrderApiIntegrationData({
                transport_id: activeManageOrder?.transport_id,
                destination_details: activeManageOrder?.destination_details,
                recipient_name: activeManageOrder?.recipient_name,
                recipient_phone_number: activeManageOrder?.recipient_phone_number,
                dropoff_verification_method: activeManageOrder?.dropoff_verification_method as ApprovalMethodEnum,
            });
        } else {
            appState?.ReservedOrders?.setEditOrderApiIntegrationData(initialEditOrderApiIntegrationData)
        }
        setEditOrder(bool);
    }
    const handleConfirm = async () => {
        if (activeManageOrder) {
            setLoading(true);
            const result = await appState?.ReservedOrders.confirmReservedOrderApiIntegration(activeManageOrder?.transport_id);
            if (result?.code === 200) {
                const login = appState.login.loggedin;
                if ((login) || (!login && appState.orders.length <= 0)) {
                    await appState.addOrder();
                    appState.selectOrder(appState.orders.length - 1);
                    const new_transport = result?.new_transport;
                    const parcel_img_url = await fetchDownloadUrl(new_transport?.parcel_img_url);
                    setReservedOrder(appState, { ...new_transport, parcel_img_url });
                }
                setLoading(false);
                appState?.ReservedOrders.setReservedOrdersOpen(false);
                handleClose();
            } else {
                setLoading(false);
            }
        }
    }
    return (
        <Dialog open={open} hideBackdrop={true} onClose={handleClose} PaperProps={{ style: { borderRadius: "10px", maxWidth: "100%", boxShadow: "none" } }}>
            <div className={styles.root}>
                <div className={styles.header}>
                    <div>
                        <h4>Order {activeManageOrder?.client_order_number}</h4>
                    </div>
                    <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M9.79992 19.833L13.9999 15.633L18.1999 19.833L19.8333 18.1997L15.6333 13.9997L19.8333 9.79967L18.1999 8.16634L13.9999 12.3663L9.79992 8.16634L8.16659 9.79967L12.3666 13.9997L8.16659 18.1997L9.79992 19.833ZM13.9999 25.6663C12.386 25.6663 10.8694 25.3601 9.44992 24.7476C8.03047 24.1351 6.79575 23.3038 5.74575 22.2538C4.69575 21.2038 3.8645 19.9691 3.252 18.5497C2.6395 17.1302 2.33325 15.6136 2.33325 13.9997C2.33325 12.3858 2.6395 10.8691 3.252 9.44967C3.8645 8.03023 4.69575 6.79551 5.74575 5.74551C6.79575 4.69551 8.03047 3.86426 9.44992 3.25176C10.8694 2.63926 12.386 2.33301 13.9999 2.33301C15.6138 2.33301 17.1305 2.63926 18.5499 3.25176C19.9694 3.86426 21.2041 4.69551 22.2541 5.74551C23.3041 6.79551 24.1353 8.03023 24.7478 9.44967C25.3603 10.8691 25.6666 12.3858 25.6666 13.9997C25.6666 15.6136 25.3603 17.1302 24.7478 18.5497C24.1353 19.9691 23.3041 21.2038 22.2541 22.2538C21.2041 23.3038 19.9694 24.1351 18.5499 24.7476C17.1305 25.3601 15.6138 25.6663 13.9999 25.6663ZM13.9999 23.333C16.6055 23.333 18.8124 22.4288 20.6208 20.6205C22.4291 18.8122 23.3333 16.6052 23.3333 13.9997C23.3333 11.3941 22.4291 9.18717 20.6208 7.37884C18.8124 5.57051 16.6055 4.66634 13.9999 4.66634C11.3944 4.66634 9.18742 5.57051 7.37909 7.37884C5.57075 9.18717 4.66659 11.3941 4.66659 13.9997C4.66659 16.6052 5.57075 18.8122 7.37909 20.6205C9.18742 22.4288 11.3944 23.333 13.9999 23.333Z" fill="#858585" />
                        </svg>
                    </Button>
                </div>
                <div className={styles.order_part}>
                    <div className={styles.order_header}>
                        <img src="/confirmation-number.webp" alt="" width={20} height={20} />
                        <span>{activeManageOrder?.business_name || 'Seyco Invoice No.'} : {activeManageOrder?.client_order_number}</span>
                    </div>
                    <OrderData />
                    <Information />
                    <div className={styles.made_of_transport_part}>
                        <ModeOfTransport />
                        <ReservationSource />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Delete />
                    <Button className={styles.edit} type="button" onClick={() => { handleEdit(true) }}>Edit Order</Button>
                    <Button className={styles.confirm} type="button" onClick={handleConfirm}>Confirm</Button>
                </div>
                {editOrder && <EditOrderPopup open={editOrder} handleClose={() => { handleEdit(false) }} />}
                {loading && <DisabledClickLoading />}
            </div>
        </Dialog>
    )
}
export default ManageOrderPopup;