import React from "react";
import { Button, Dialog } from "@material-ui/core";
import BearerTextField from "../../components/fields/BearerTextField";
import { Observer, observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from "./ResetPasswordPopup.module.scss"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

interface IResetPasswordProps { open: boolean, handleClose: () => void }
const ResetPasswordPopup: React.FC<IResetPasswordProps> = observer(({ open, handleClose }) => {
    const appState = useAppState();
    const login = appState.login;

    const [openSuccessfulAlert, setOpenSuccessfulAlert] = React.useState(false);
    const handleOpenSuccessfulAlert = () => {
        setOpenSuccessfulAlert(true);
    }
    const handleSuccessfulAlertClose = () => {
        setOpenSuccessfulAlert(false);
    }

    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        setLoading(true);
        await appState.login.fetchResetPassword();
        setLoading(false);
        handleClose();
        handleOpenSuccessfulAlert();
    }
    // modified by Bearer Web Developer start
    return (
        <>
            <SuccessfulAlert open={openSuccessfulAlert} handleClose={handleSuccessfulAlertClose} />
            <Dialog
                onClose={handleClose} PaperProps={{ style: { borderRadius: "10px" } }}
                open={open}
            >
                <div className={styles.root}>
                    <div className={styles.header}>
                        <div>
                            <h5>Forgotten your password?</h5>
                        </div>
                        <div>
                            <Button onClick={handleClose}>
                                <img src="/back.png" alt="back" />
                            </Button>
                        </div>
                    </div>
                    <h6>Please enter your Bearer username (Email address)</h6>
                    <div>
                        <div>
                            <BearerTextField
                                colorStyle="gray"
                                label="Email address"
                                value={login.email}
                                onChange={(e) => login.setEmail(e.target.value)}
                                clear={()=>{ login.setEmail('') }} // added by Bearer Web Developer
                                validator={validateEmail}
                            />
                        </div>
                        <div>
                            <Observer>{() => {
                                const valid = !!login.email && !!login.email.trim()
                                return (
                                    <>
                                        <Button
                                            className={styles.submit}
                                            onClick={handleConfirm}
                                            disabled={!valid}
                                        >
                                            Submit
                                        </Button>
                                        {loading && <DisabledClickLoading />}
                                    </>
                                )
                            }}</Observer>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
    // modified by Bearer Web Developer end
})
export default ResetPasswordPopup;




interface ISuccessfulAlertProps { open: boolean, handleClose: () => void }
const SuccessfulAlert: React.FC<ISuccessfulAlertProps> = ({ open, handleClose }) => {
    // modified by Bearer Web Developer start
    return (
        <Dialog open={open}>
            <div className={styles.root}>
                <div className={styles.header}>
                    <div>
                        <h6>Done!</h6>
                    </div>
                    <div>
                        <Button onClick={handleClose}>
                            <img src="/back.png" alt="back" />
                        </Button>
                    </div>
                </div>
                <p>If you already have an account with Bearer, please check your email and follow the instructions to reset your password.</p>

                <div className={''}>
                    <Button className={styles.submit} onClick={handleClose}>Ok</Button>
                </div>
            </div>
        </Dialog>
    )
    // modified by Bearer Web Developer end

}



function validateEmail(value: string) {
    if (!(value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) return 'Email address is not valid!'
}