/* this file added by Bearer Web Developer  */

import { observer } from 'mobx-react-lite';
import BearerTextField from '../../../../../../components/fields/BearerTextField';
import { useAppState } from '../../../../../../states/app-state';
import styles from './information.module.scss';
import RightRadioButton from '../../../../../../components/rightradiobutton/RightRadioButton';
import { ApprovalMethodEnum } from '../../../../../../states/reservedOrdersState/models/editOrderApiIntegration';
import { RecipientName, RecipientPhoneNumberCode, RecipientPhoneNumberNone } from '../validateForm';

const EditInformation: React.FC = observer(() => {
    const appState = useAppState();
    const { activeManageOrder, setEditOrderApiIntegrationData } = appState?.ReservedOrders;
    const editData = appState?.ReservedOrders.editOrderApiIntegrationData;
    const RecipientPhoneNumber =
        editData?.dropoff_verification_method === "Code" ? RecipientPhoneNumberCode : RecipientPhoneNumberNone;

    return (
        <div className={styles.information}>
            <ul>
                <li>
                    <img src="/person-black.webp" alt="" width={21} height={21} />
                    <div className={styles.recipient_name_input}>
                        <BearerTextField
                            colorStyle='gray'
                            placeholder={"Recipient’s Name"}
                            label="Recipient’s Name:"
                            inputSmallStyle={true}
                            value={editData.recipient_name}
                            onChange={(e) => setEditOrderApiIntegrationData({ recipient_name: e.target.value })}
                            clear={() => { setEditOrderApiIntegrationData({ recipient_name: '' }) }}
                            validator={RecipientName}
                        />
                    </div>
                </li>
                <li>
                    <img src="/orders-grey.webp" alt="" width={21} height={21} />
                    <div>
                        <div>The verification method for the recipient at the destination.</div>
                        <div>
                            <RightRadioButton
                                gray={true}
                                label="SMS Code"
                                checked={editData?.dropoff_verification_method === ApprovalMethodEnum.Code}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setEditOrderApiIntegrationData({
                                            dropoff_verification_method: ApprovalMethodEnum.Code
                                        });
                                        setEditOrderApiIntegrationData({ recipient_phone_number: "+614" });
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <RightRadioButton
                                gray={true}
                                label="None"
                                checked={editData?.dropoff_verification_method === ApprovalMethodEnum.None}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setEditOrderApiIntegrationData({
                                            dropoff_verification_method: ApprovalMethodEnum.None
                                        });
                                        setEditOrderApiIntegrationData({ recipient_phone_number: "+61" });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </li>
                <li>
                    <img src="/call-grey.webp" alt="" width={21} height={21} />
                    <div className={styles.recipient_number_input}>
                        <BearerTextField
                            colorStyle='gray'
                            placeholder={"Recipient’s Number"}
                            label="Recipient’s Number:"
                            inputProps={{ maxLength: "12" }} // add maxlength of Phone Number of origin by Bearer Web Developer
                            inputSmallStyle={true}
                            value={editData.recipient_phone_number}
                            onChange={(e) => {
                                const beginningNumber = editData?.dropoff_verification_method === ApprovalMethodEnum.Code ? 4 : 3;
                                const beginning = editData?.dropoff_verification_method === ApprovalMethodEnum.Code ? "+614" : "+61";
                                const text = e.target.value.slice(0, beginningNumber) !== beginning ? beginning + e.target.value.slice(beginningNumber) : e.target.value;
                                setEditOrderApiIntegrationData({ recipient_phone_number: text });
                            }}
                            clear={() => {
                                const beginning = editData?.dropoff_verification_method === ApprovalMethodEnum.Code ? "+614" : "+61";
                                setEditOrderApiIntegrationData({ recipient_phone_number: beginning })
                            }}
                            validator={RecipientPhoneNumber}
                        />
                    </div>
                </li>
                <li className={styles.parcel_weight}>
                    <img src="/weight-grey.webp" alt="" width={21} height={21} />
                    Parcel Weight:<br />{`${activeManageOrder?.parcel_min_weight} ~ ${activeManageOrder?.parcel_max_weight} KG`}
                </li>
            </ul>
        </div>
    )
})
export default EditInformation;