/* this file added by Bearer Web Developer  */
import React from 'react';
import styles from './orders.module.scss';
import Order from './order';
import { useAppState } from '../../../states/app-state';
import { TReservedOrderDetails } from '../../../states/reservedOrdersState/models/reservedOrderDetails';
import { observer } from "mobx-react-lite";
import ManageOrderPopup from './manageOrderPopup';

const Orders: React.FC = observer(() => {
    const appState = useAppState();
    const { activeManageOrder } = appState?.ReservedOrders
    const CloseManageOrder = () => {
        appState?.ReservedOrders?.setActiveManageOrder(null);
        if (appState?.ReservedOrders?.sortBasis) appState?.ReservedOrders?.setSortBasis(null);
    }
    const { reservedOrdersList } = appState.ReservedOrders;
    return (
        <div className={styles.orders}>
            {reservedOrdersList?.map((r: TReservedOrderDetails) => <Order key={r?.transport_id} reservedOrderInfo={r} />)}
            {!!activeManageOrder && <ManageOrderPopup open={!!activeManageOrder} handleClose={CloseManageOrder} />}
        </div>
    )
})
export default Orders;