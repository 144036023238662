import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.white,
        '& .container': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },

}));
export default function DisabledClickLoading() {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={true} >
            <div className="container" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <CircularProgress color="inherit" />
            </div>
        </Backdrop>

    )
}