import { createTheme } from "@material-ui/core";



const createAppTheme = createTheme({
    palette: {
        bearer: {
            origin: "#275aaf",
            destination: "#275aaf",
            path: "#275aaf",
            orange: "#fd7f23", // orange color added by Bearer Web Developer
        },
        common: {
            black: "#000",
            white: "#fff",
        },
        primary: {
            light: '#63c3ff',
            main: '#009eff',
            dark: '#275aaf',

        },
        grey: {
            '100': '#f5f5f5',
            'A100': '#dedede',
            '300': '#e0e0e0',
        },
        warning: {
            main: '#ff8a29',
            contrastText: '#000',
        },
        error: {
            light: '#f50057',
            main: '#ff2c2c',
        },
        action: {
            selected: '#009eff',
        },
        success: {
            main: '#4ad395',
        },
    },
    typography: {
        fontFamily: [
            'gotham',
        ].join(','),
        allVariants: {
            color: '#707070',
        },
        h6: {
            fontSize: 16,
        },
        fontSize: 13,
    },
    shape: {
        borderRadius: 2,
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },

        },
        MuiTextField: {

        },
    }
});
export default createAppTheme;