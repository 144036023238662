
// added by Bearer Web Developer
const googleTagManager = () => {
    const script = document.createElement('script');
    script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MBDK77G');
    `;
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-MBDK77G";
    iframe.height = '0';
    iframe.width = '0';
    iframe.setAttribute('style',"display:none;visibility:hidden");
    noscript.appendChild(iframe);
    
    document.head.appendChild(script);
    document.body.appendChild(noscript);

}

export default googleTagManager