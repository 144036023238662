import React from "react"
import { Button } from "@material-ui/core"
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './CancelByRider.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const CancelByRider: React.FC = () => {
    const appState = useAppState();

    const [loadingSearchAgain, setLoadingSearchAgain] = React.useState(false);
    const handleTransportRequestAgain = async () => {
        setLoadingSearchAgain(true);
        await appState.selectedOrder?.transportRequestAgain();
        setLoadingSearchAgain(false);
    }

    const [loadingCancel, setLoadingCancel] = React.useState(false);
    const handleCustomerCancelTransportAfterCancelByRider = async () => {
        setLoadingCancel(true);
        await appState.selectedOrder?.customerCancelTransportAfterCancelByRider();
        setLoadingCancel(false);
    }
    //  modified by Bearer Web Developer start
    return (
        <div className={styles.root}>

            <p>Sorry, unfortunately the assigned Bearer was unable to do this job. Do you wish to search for another Bearer for this order?</p>
            <div className={styles.confirm}>

                <Button
                    className={styles.no}
                    onClick={handleCustomerCancelTransportAfterCancelByRider}
                    disabled={loadingCancel}
                >
                    <span>No</span>
                </Button>
                {loadingCancel && <DisabledClickLoading />}

                <Button
                    className={styles.yes}
                    onClick={handleTransportRequestAgain}
                    disabled={loadingSearchAgain}
                >
                    <span>Yes</span>
                </Button>
                {loadingSearchAgain && <DisabledClickLoading />}

            </div>
        </div>

    )
    //  modified by Bearer Web Developer end
}

export default CancelByRider;



